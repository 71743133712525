import React, { useState, useEffect } from "react";
import axios from "axios";
import Loader from "../Loader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa6";
import { Menu } from "../header/Header";
import { Button } from "@mui/material";
import { fetchCurrentDateTime } from "../commonModules/CommonFunctions";

const ShiftOverview = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const [mapSrc, setMapSrc] = useState("");
  const userData = JSON.parse(sessionStorage.getItem("CurrentUserData"));
  const AtlasEmpID = userData.AtlasEmpID;

  // const labels = ["S.No","AEC ID","Employee Name","Department","Designation","Leave Type","Status",""]
  const labels = [
    "S.No",
    "AEC ID",
    "Employee Name",
    "Department",
    "Designation",
    "Punch-in Time",
    "Punch-in Out",
  ];

  const [shiftDetails, setShiftDetails] = useState([]);
  const [searchdate, setSearchDate] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  
  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`; // Converts DD/MM/YYYY to YYYY-MM-DD
  };
  useEffect(() => {
    if (!token) {
      navigate("/");
    } else {
      const { date } = fetchCurrentDateTime();
      const formattedDate = formatDate(date); // Convert the date here
      setSearchDate(formattedDate);
      // fetchBaseLocationList(formattedDate); // Pass the formatted date if needed
    }
  }, []);
  
  useEffect(() => {
    const fetchShiftDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${api_url}/shift-overview/shift-details/${AtlasEmpID}/${searchdate}`
        );
        const result = response.data.data;
        setShiftDetails(result);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    if(searchdate){
      fetchShiftDetails();  
    }
   
  
    
  }, [searchdate,]);


  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="container-fluid">
          <div className="button-wrapper">
            <div>
              <Menu link="/approval-dash" />
            </div>
          </div>
          {/* </div> */}
          <div className="table-view-container-wrapper">
            <div className="p-2 d-flex fw-bold shadow justify-content-between align-items-center mb-3">
              <span className="mx-auto">Shift Overview</span>
              <div style={{ flex: "0 0 auto" }}>
                <input
                  type="date"
                  className="form-input-date"
                  name="date-filter"
                  id="date-filter"
                  value={searchdate}
                  onChange={(e) => setSearchDate(e.target.value)}
                />
              </div>
            </div>
            <table className="table table-hover table-responsive">
              <thead>
                <tr>
                  {labels.map((item, index) => (
                    <th
                      scope="col"
                      key={index}
                      className="table-header-font-size"
                    >
                      {item}{" "}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="table-body-font-size">
                {shiftDetails.map((item, index) => {
                  const sno = index + 1;
                  return (
                    <tr
                      className="py-0"
                      key={index}
                      // data-bs-toggle="modal"
                      // data-bs-target="#baselocationmodal"
                      // onClick={() => handleRowClick(item.PunchID)}
                    >
                      <td className="py-0">{sno}</td>
                      <td className="py-0">{item.AtlasEmpID}</td>
                      <td className="py-0">{item.EmpName}</td>
                      <td className="py-0">{item.Department}</td>
                      <td className="py-0">{item.Designation}</td>
                      <td className="py-0">{item.FirstPunchIn}</td>
                      <td className="py-0">{item.LastPunchOut}</td>

                      {/* <td className="py-0">
                        <Link
                          to="/requests/leave-request-form"
                          state={{ reqid: item.LongLatID }}
                        >
                          <FaEye />
                        </Link>
                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default ShiftOverview;
