import React, { useState,useEffect } from 'react';
import "./LeaveApprovalList.css";
import Loader from '../../Loader';
import axios from 'axios';
import { Menu } from '../../header/Header';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaEye } from "react-icons/fa6";

const LeaveApprovalList = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const [loading,setLoading] = useState(false)
  const userData = JSON.parse(sessionStorage.getItem("CurrentUserData"));
  const labels = ["S.No","AEC ID","Employee Name","Department","Designation","Leave Type","Status",""]
  
  const [leavelist,setLeaveList] = useState([]);
  const [listtype, setListType] = useState("P");
  const navigate = useNavigate();
  const token = localStorage.getItem("token")
  
  // const AtlasEmpID = localStorage.getItem("AtlasEmpID");
  const AtlasEmpID = userData.AtlasEmpID;
  
//Who is opening the form
const UserInd = 
  useEffect(() => {
    if (!token) {
      navigate("/");
      return;
    }
    if(userData.Designation === "HR Manager"){
      const fetchHRApprovalRequests = async()=>{
        try {
          const payload = { ops: listtype };
          // const response = await axios.post(`${api_url}/request/gethrapprovalitems`);
          const response = await axios.post(`${api_url}/request/gethrapprovalitems`,payload, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          const  result= response.data.data;
          setLeaveList(result);
        } catch (error) {
          console.log(error);
          setLoading(true)
        } 
        finally {
          setLoading(false); 
        }
      }
      fetchHRApprovalRequests();
    }
    else{
      const fetchData = async () => {
        setLoading(true); // Start loading
        try {
          const payload = { ops: listtype };
          const response = await axios.post(`${api_url}/request/getmanagerapprovalitems/${AtlasEmpID}`,payload, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          const  result= response.data.data;
          console.log("approvallist",response.data.data)
          setLeaveList(result);
        } catch (error) {
          console.log(error);
          setLoading(true)
        } 
        finally {
          setLoading(false); 
        }
      };
    
      fetchData();
    }
  }, [listtype]);

  // const link = userData.AtlasEmpID==="AEC002"?"/hrdash":"/approval-dash";

  return (
    <>
     
     {loading ? (
      <Loader/>
     ):
     <div className="container-fluid">
          <div className="button-wrapper">
            <div>
            <Menu link="/approval-dash"/>
            </div>
      
          </div>
        {/* </div> */}
        <div className="table-view-container-wrapper">
            <div className="p-2 d-flex fw-bold shadow justify-content-between align-items-center mb-3">
              <span className="mx-auto">Leave Approval List</span>
              <div style={{ flex: '0 0 auto' }}>
                <select
                  style={{ width: `150px` }}
                  name="leaverequestlist"
                  className="form-input-select"
                  aria-label=".form-select-sm example"
                  onChange={(e) => setListType(e.target.value)}
                  value={listtype || ""}
                  required
                >
                  <option value="All">All Requests</option>
                  <option value="A">Approved</option>
                  <option selected value="P">Pending</option>
                  <option value="R">Rejected</option>
                </select>
              </div>
            </div>
          <table className="table table-hover table-responsive">
        <thead>
          <tr>
            {labels.map((item, index) => (
              <th scope='col' key={index} className="table-header-font-size">{item} </th>
            ))}
          </tr>
        </thead>
        <tbody className='table-body-font-size'>
          {leavelist.map((item, index) => {
            const sno = index + 1;
            return (
              <tr className="py-0" key={index}  onClick={() => {
                navigate("/requests/leave-request-form", {
                  state: { reqid: item.RequestID },
                });
              }}  style={{ cursor: "pointer" }}>
                <td className="py-0">{sno}</td>
                <td className="py-0">{item.AtlasEmpID}</td>
                <td className="py-0">{item.EmpName}</td>
                <td className="py-0">{item.Department}</td>
                <td className="py-0">{item.Designation}</td>
                <td className="py-0">{item.LeaveType}</td>
                {userData.AtlasEmpID==="AEC002"?
                <td className="py-0">
                {item.HRApproval.trim() === "P" ? "In Progress" :
                  item.HRApproval.trim() === "A" ? "Approved" :
                      item.HRApproval.trim() === "R" ? "Rejected" : ""}
              </td>
              :
              <td className="py-0">
                        {item.ReportingManagerApproval.trim() === "P" ? "In Progress" :
                          item.ReportingManagerApproval.trim() === "A" ? "Approved" :
                              item.ReportingManagerApproval.trim() === "R" ? "Rejected" : ""}
                      </td>
              }
                
                <td className="py-0">
                  <Link
                    to="/requests/leave-request-form"
                    state={{ reqid: item.RequestID }}
                  >
                    <FaEye/>
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
        </div>
      </div>
     }
    </>
  )
}

export default LeaveApprovalList;