import React, { useState, useEffect } from "react";
import { Menu } from "../../header/Header";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

import SearchIcon from "@mui/icons-material/Search";
import "./RecruitmentPlanList.css";
import Loader from "../../Loader";
import { FaEye } from "react-icons/fa6";

const RecruitmentPlanList = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [planList, setPlanList] = useState([]);
  const [loading,setLoading] = useState(true);
  const [years,setYears] = useState([]);


  useEffect(() => {
    YearSelect();
    if (!token) {
      navigate("/");
      }  
      const GetPlanList = () => {
        setLoading(true);
        try{
            axios
            .get(`${api_url}/hr/recruitment-plan/get-plans-list`)
            .then((res) => {
                setPlanList(res.data.list);
              })
              setLoading(false);
        }
        catch(err){
            console.log(err);
        }
       
      };  
    GetPlanList();

  }, []);

  const YearSelect = () => {
    // Generate an array of years from 2006 to 2024
    const startYear = 2024;
    const endYear = 2028;
    const years_list = [];

    for (let year = startYear; year <= endYear; year++) {
      years_list.push(year);
    }
    setYears(years_list);
  };

  return (
    <>
    {loading ? (
      <Loader />
    ) : (
      <div className="my-1 mx-5 p-1">
        <div className="d-block">
          <div className="d-inline-block align-middle">
            <Menu link="/hrdash" />
          </div>
          <div className="d-inline-block p-1 float-xxl-end float-xl-end float-md-end float-sm-end">
            <Link to="/hr/recruitment-plan-form" state={{ mode: "add" }}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                endIcon={<AddIcon />}
              >
                New Plan
              </Button>
            </Link>
          </div>
        </div>
        <div className="table-view-container-wrapper">
          <div className="p-2 d-flex fw-bold shadow justify-content-center blockquote">
                      Recruitment Plan List
          </div>
          <div className="search-bar d-flex justify-content-start  d-flex">
            <div className="search-dept mb-2">
              <select
                name="department"
                className="form-input-select"
                id="department"
                aria-label="Small select example"
                // onChange={(e) => {
                //   handleSearchDropdownChange(e);
                // }}
              >
                <option value="" defaultValue={""}>
                  2024
                </option>
                {years.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
              </select>
            </div>
          </div>
          <div>
            <table className="table table-hover table-responsive">
              <thead>
                <tr>
                  <th className="table-header-font-size">S.No</th>
                  <th className="table-header-font-size">Department</th>
                  <th className="table-header-font-size">Designation</th>
                  <th className="table-header-font-size">Nationality</th>
                  <th className="table-header-font-size">No of Count</th>
                  <th className="table-header-font-size">Current Revision</th>
                  <th className="table-header-font-size"></th>
                </tr>
              </thead>
          
              <tbody className="table-body-font-size">
                {planList.map((item, index) => (
                  <tr className="py-0" scope="row" key={item.CVID} onClick={()=>{
                    navigate("/hr/recruitment-plan-form",{state: {mode:"edit",reqid: item.RequestID}})
                  }}
                  style={{ cursor: 'pointer' }}>
                    <td className="py-0">{index + 1}</td>
                    <td className="py-0">{item.Department}</td>
                    <td className="py-0">{item.Designation}</td>
                    <td className="py-0">{item.Nationality}</td>
                    <td className="py-0">{item.NoofCount}</td>
                    <td className="py-0">{item.CurrentRevision}</td>
                    <td className="py-0">
                      <Link
                        to="/hr/recruitment-plan-form"
                        state={{
                          mode: "edit",
                          reqid: item.RequestID,
                        }}
                      >
                        <EditIcon />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )}
  </>

  );
};

export default RecruitmentPlanList;
