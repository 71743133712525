import React, { useState,useEffect } from 'react';
import "./ITCompliantRequestList.css";
import Loader from '../../../Loader';
import axios from 'axios';
import { Menu } from '../../../header/Header';
import { Link, useNavigate } from 'react-router-dom';
import { FaEye } from "react-icons/fa6";
import { Button } from '@mui/material'; 
import AddIcon from '@mui/icons-material/Add'; 


const ITComplaintRequestList = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const [loading,setLoading] = useState(true)
  const userData = JSON.parse(sessionStorage.getItem("CurrentUserData"));
  const labels = ["S.No","Title","SubmittedDate","ClosedDate","StatuInd",""]

  const labelsforAdmin = ["S.No","AtlasEmpID","Emp Name","Title","Submitted Date","ClosedDate","StatuInd",""]
  
  const [requestList,setRequestList] = useState([]);
  const [listtype, setListType] = useState("O");
  const navigate = useNavigate();
  const token = localStorage.getItem("token")
  
   

  useEffect(() => {
    if (!token) {
      navigate("/");
      return;
    }
    if(userData.AtlasEmpID==="AEC057"){
      const fetchData = async () => {
        setLoading(true); // Start loading
        try {
          const payload = { ops: listtype };
          const response = await axios.post(`${api_url}/request/getlistoftickets/admin`,payload, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          const  result= response.data.list;
          // console.log("result of api call",result);
          setRequestList(result);
          setLoading(false); 
        } catch (error) {
          console.log(error);
          setLoading(true)
        } 
      };
    
      fetchData();
    }
    else{
      const fetchData = async () => {
        setLoading(true); // Start loading
        try {
          const payload = { ops: listtype };
          const response = await axios.post(`${api_url}/request/get-it-ticket-list/${userData?.AtlasEmpID}`,payload, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          const  result= response.data.list;
          // console.log("result of api call",result);
          setRequestList(result);
          setLoading(false); 
        } catch (error) {
          console.log(error);
          setLoading(true)
        } 
      };
      fetchData();
    }
    
    
     
  }, [listtype]);


  return (
    <>
     
     {loading ? (
      <Loader/>
     ):
     <div className="container-fluid">
          <div className="button-wrapper">
            <div>
            <Menu link="/requestdashboard"/>
            </div>
            <div>
              <Link to="/requests/it-complaint-request-form" state={{ reqid: "new" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<AddIcon />}
                >
                  Raise a Ticket
                </Button>
              </Link>
            </div>
      
          </div>
        {/* </div> */}
        <div className="table-view-container-wrapper">
            <div className="p-2 d-flex fw-bold shadow justify-content-between align-items-center mb-3">
              <span className="mx-auto">IT Ticket List</span>
              <div style={{ flex: '0 0 auto' }}>
                <select
                  style={{ width: `150px` }}
                  name="itrequestlist"
                  className="form-input-select"
                  aria-label=".form-select-sm example"
                  onChange={(e) => setListType(e.target.value)}
                  value={listtype || ""}
                  required
                >
                  <option value="All">All Requests</option>
                  <option selected value="O">Open</option>
                  <option value="C">Closed</option>
                </select>
              </div>
            </div>
            {
              userData.AtlasEmpID==="AEC057"?
              <table className="table table-hover table-responsive">
              <thead>
                <tr>
                  {labelsforAdmin.map((item, index) => (
                    <th scope='col' key={index} className="table-header-font-size">{item} </th>
                  ))}
                </tr>
              </thead>
              <tbody className='table-body-font-size'>
                {requestList?.map((item, index) => {
                  const sno = index + 1;
                  return (
                    <tr className="py-0" key={index}>
                      
                      <td className="py-0">{sno}</td>
                      <td className="py-0">{item.AtlasEmpID}</td>
                      <td className="py-0">{item.EmpName}</td>
                      <td className="py-0">{item.Title}</td>
                      <td className="py-0">{item.SubmittedDate}</td>
                      <td className="py-0">{item.ClosedDate}</td>
                      
                      <td className="py-0">
                      {item.StatusInd.trim() === "O" ? "Open" :
                         item.StatusInd.trim() === "C" ? "Closed" : ""}
                    </td> 
                  
                      <td className="py-0">
                        <Link
                          to="/requests/it-complaint-request-form"
                          state={{ reqid: item.RequestID }}
                        >
                          <FaEye/>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>:
             <table className="table table-hover table-responsive">
             <thead>
               <tr>
                 {labels.map((item, index) => (
                   <th scope='col' key={index} className="table-header-font-size">{item} </th>
                 ))}
               </tr>
             </thead>
             <tbody className='table-body-font-size'>
               {requestList?.map((item, index) => {
                 const sno = index + 1;
                 return (
                   <tr className="py-0" key={index}>
                     <td className="py-0">{sno}</td>
                     <td className="py-0">{item.Title}</td>
                     <td className="py-0">{item.SubmittedDate}</td>
                     <td className="py-0">{item.ClosedDate}</td>
                     
                     <td className="py-0">
                     {item.StatusInd.trim() === "O" ? "Open" :
                        item.StatusInd.trim() === "C" ? "Closed" : ""}
                   </td> 
                 
                     <td className="py-0">
                       <Link
                         to="/requests/it-complaint-request-form"
                         state={{ reqid: item.RequestID }}
                       >
                         <FaEye/>
                       </Link>
                     </td>
                   </tr>
                 );
               })}
             </tbody>
           </table>
            }
         
        </div>
      </div>
     }
    </>
  )
}

export default ITComplaintRequestList;