import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import axios from 'axios'; // Make sure axios is imported
import Loader from '../../../Loader';
import { Menu } from '../../../header/Header';
import { Button } from '@mui/material'; // Adjust the import as per your UI library
import AddIcon from '@mui/icons-material/Add'; // Adjust if using Material-UI
import { FaEye } from 'react-icons/fa';

const LeaveRequestList = () => {
  const userData = JSON.parse(sessionStorage.getItem("CurrentUserData"));
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [leaveList, setLeaveList] = useState([]);
  const [listtype, setListType] = useState("P");
  const { list } = location.state || {};
  const api_url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const AtlasEmpID = localStorage.getItem("AtlasEmpID");
  const token = localStorage.getItem("token");

useEffect(() => {
  if (!token) {
    navigate("/");
  }
}, [token, navigate]); // Include token and navigate in the dependency array



  const labels = [
    "S.No",
    "LeaveType",
    "LeaveStarts",
    "LeaveEnds",
    "SubmittedDate",
    "ReportingManagerApprovedDate",
    "HRApprovedDate",
    "Status",
    "",
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = { ops: listtype };
        const res = await axios.post(
          `${api_url}/requests/leave-requests/${AtlasEmpID}`, payload, {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const leavelist = res.data.list || []; // Ensure leavelist is an array
        setLeaveList(leavelist);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [listtype]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="container-fluid">
          <div className="button-wrapper">
            <div>
              <Menu link="/requestdashboard" />
            </div>
            <div>
              <Link to="/requests/leave-request-form" state={{ reqid: "new" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<AddIcon />}
                >
                  Request Leave
                </Button>
              </Link>
            </div>
          </div>
          <div className="table-view-container-wrapper">
            <div className="p-2 d-flex fw-bold shadow justify-content-between align-items-center mb-3">
              <span className="mx-auto">Leave Request List</span>
              <div style={{ flex: '0 0 auto' }}>
                <select
                  style={{ width: `150px` }}
                  name="leaverequestlist"
                  className="form-input-select"
                  aria-label=".form-select-sm example"
                  onChange={(e) => setListType(e.target.value)}
                  value={listtype || ""}
                  required
                >
                  <option value="All">All Requests</option>
                  <option value="A">Approved</option>
                  <option value="P">Pending</option>
                  <option value="R">Rejected</option>
                  <option value="C">Saved</option>
                </select>
              </div>
            </div>
            <table className="table table-hover table-responsive">
              <thead>
                <tr>
                  {labels.map((item, index) => (
                    <th scope="col" key={index} className="table-header-font-size">
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="table-body-font-size">
                {leaveList.map((item, index) => {
                  const sno = index + 1;
                  return (
                    <tr className="py-0" key={index}
                    onClick={() => {
                      navigate("/requests/leave-request-form", {
                        state:{ reqid: item.RequestID},
                      });
                    }}
                    style={{ cursor: "pointer" }}>
                      <td className="py-0">{sno}</td>
                      <td className="py-0">{item.LeaveType}</td>
                      <td className="py-0">{item.LeaveStarts}</td>
                      <td className="py-0">{item.LeaveEnds}</td>
                      <td className="py-0">{item.SubmittedDate}</td>
                      <td className="py-0">{item.ReportingManagerApprovedDate}</td>
                      <td className="py-0">{item.HRApprovedDate}</td>
                      <td className="py-0">
                        {item.StatusInd === "P" ? "In Progress" :
                          item.StatusInd === "A" ? "Approved" :
                            item.StatusInd === "C" ? "Saved" :
                              item.StatusInd === "R" ? "Rejected" : ""}
                      </td>
                      <td className="py-0">
                        <Link to="/requests/leave-request-form" state={{ reqid: item.RequestID }}>
                          <FaEye />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default LeaveRequestList;
