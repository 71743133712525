import React, { useState, useEffect } from "react";
import axios from "axios";



import { PDFDocument, rgb, StandardFonts } from "pdf-lib";

const inchesToPoints = (inches) => inches * 72; 

const Text = () => {
  
  const [offerDetails, setOfferDetails] = useState("");
  const api_url = "https://atlas-om.app:30000/api";

 useEffect(() => {
  const getDraftOfferDetails = async () => {
    try {
      const res = await axios.get(
        `${api_url}/recruitment/recruitment-master/edit-recruitment-details/18`
      );
      const response = res.data.data;
      console.log("response from api", response);
      setOfferDetails(response);

      if (res.data.success === true) {
      // setLoading(false)
      }
    } catch (err) {
      console.log("Error fetching Draft Offer Details", err);
      // setLoading(true)
    }
  };

   getDraftOfferDetails();
}, []);



const updateDate = () => {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, "0");
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const year = now.getFullYear();

  return `${day}/${month}/${year}`;
};


const getDraftOfferTemplate = async (filename) => {
  try {
    const response = await axios.get(
      `${api_url}/recruitment/recrutiment-master/get-draft-offer-template/${filename}`,
      { responseType: 'blob' }
    );
    return response.data; // Return the PDF blob instead of setting it to state
  } catch (err) {
    console.error("Error fetching PDF:", err);
    return null; // Return null in case of error
  }
};


  const addTextToPDF = async () => {
    const filename = offerDetails.Nationality==="Omani"?"Draft offer letter Omani":"Draft offer letter Non-Omani"
    const pdfBlob = await getDraftOfferTemplate(filename);
    if (!pdfBlob) {
      console.error("PDF file is missing!");
      return null; 
    }
    // Read the PDF file
    const existingPdfBytes = await pdfBlob.arrayBuffer();
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const page = pdfDoc.getPages()[0];


    // Load the font
    const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold); // Using bold font


    // coordinates in inches
    const EmpNameCoords = { x: 0.51, y: 2.04 };
    const DateCoords = { x: 6.9, y: 1.69 };
    const positionCoords = { x: 2.67, y: 3.825 };
    const reportingToCoords = { x: 2.67, y: 4.105 };
    const totalsalaryCoords = { x: 5.6, y: 4.37 };
    const basicsalaryCoords = { x: 3.73, y: 4.66 };
    const allowanceCoords = { x: 5.67, y: 4.66 };
    const desginationCoords = { x: 5.5, y: 2.835 }; //2.65
    const visaCoords = { x: 2.68, y: 5.22 };
    const medicalCoords = { x: 2.68, y: 4.95 };

    const Date = updateDate();
    page.drawText(Date, {
      x: inchesToPoints(DateCoords.x),
      y: page.getHeight() - inchesToPoints(DateCoords.y),
      size: 10,
      // font: font,
      color: rgb(0, 0, 0),
    });
//EmpName Text
    const concat_name = `${offerDetails.FirstName.trim()} ${offerDetails.LastName.trim()}`;
    const title = `${offerDetails.Gender==="Female"?"Ms. ":"Mr. "}`;
    const EmpName = `Dear  ${title}${concat_name}`;
    page.drawText(EmpName, {
      x: inchesToPoints(EmpNameCoords.x),
      y: page.getHeight() - inchesToPoints(EmpNameCoords.y),
      size: 10,
      font: boldFont,
      color: rgb(0, 0, 0),
    });

     // Draw underline for EmpName text
     page.drawLine({
      start: {
        x: inchesToPoints(EmpNameCoords.x),
        y: page.getHeight() - inchesToPoints(EmpNameCoords.y) - 2, // Adjust for underline position
      },
      end: {
        x: inchesToPoints(EmpNameCoords.x) + 10 * EmpName.length * 0.6, // Length of the underline
        y: page.getHeight() - inchesToPoints(EmpNameCoords.y) - 2,
      },
      thickness: 0.7,
      color: rgb(0, 0, 0),
    });

//Designation Text
    const Designation = offerDetails.Designation.trim();
    page.drawText(Designation, {
      x: inchesToPoints(desginationCoords.x),
      y: page.getHeight() - inchesToPoints(desginationCoords.y),
      size: 10,
      font: boldFont,
      color: rgb(0, 0, 0),
    });

    // Draw the text for position
    const positionText = offerDetails.Designation.trim();
    page.drawText(positionText, {
      x: inchesToPoints(positionCoords.x),
      y: page.getHeight() - inchesToPoints(positionCoords.y),
      size: 10,
      // font: font,
      color: rgb(0, 0, 0),
    });

    // Draw the text for reportingTo
    const reportingToText = offerDetails.ReportingManagerName;
    page.drawText(reportingToText, {
      x: inchesToPoints(reportingToCoords.x),
      y: page.getHeight() - inchesToPoints(reportingToCoords.y),
      size: 10,
      color: rgb(0, 0, 0),
    });

    //Total Salary Text
    const totalSalaryText = offerDetails.TotalFixedSalary.toString();
    page.drawText(totalSalaryText, {
      x: inchesToPoints(totalsalaryCoords.x),
      y: page.getHeight() - inchesToPoints(totalsalaryCoords.y),
      size: 10,
      color: rgb(0, 0, 0),
    });


//Basic Salary Text
    const basicSalaryText = offerDetails.Basic.toString();
    page.drawText(basicSalaryText, {
      x: inchesToPoints(basicsalaryCoords.x),
      y: page.getHeight() - inchesToPoints(basicsalaryCoords.y),
      size: 10,
      color: rgb(0, 0, 0),
    });


//Allowances Text
    const allowancesText = (
      offerDetails.TotalFixedSalary - offerDetails.Basic
    ).toString();
    page.drawText(allowancesText, {
      x: inchesToPoints(allowanceCoords.x),
      y: page.getHeight() - inchesToPoints(allowanceCoords.y),
      size: 10,
      color: rgb(0, 0, 0),
    });

    //Medical Text
    const MedicalText =
      offerDetails.Medical === "Self"
        ? "Provided by company for Self."
        : "Provided by company for Self & Family.";
    page.drawText(MedicalText, {
      x: inchesToPoints(medicalCoords.x),
      y: page.getHeight() - inchesToPoints(medicalCoords.y),
      size: 10,
      // font:calibriFont,
      color: rgb(0, 0, 0),
    });


    //VisaText
      if(offerDetails.Nationality.trim() !== "Omani"){
        const VisaText =
        offerDetails.VisaStatus === "Self"
          ? "Provided by company for Self."
          : "Provided by company for Self & Family.";
      page.drawText(VisaText, {
        x: inchesToPoints(visaCoords.x),
        y: page.getHeight() - inchesToPoints(visaCoords.y),
        size: 10,
        // font:calibriFont,
        color: rgb(0, 0, 0),
      });
      }

      return pdfDoc;
    
  };

  const savePDF = async () => {
    const modifiedPdfDoc = await addTextToPDF();
        if (!modifiedPdfDoc) {
      console.error("Failed to generate PDF document.");
      return; 
    }
    const pdfBytes = await modifiedPdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "modified.pdf";
    a.click();
    URL.revokeObjectURL(url);
  }


  return (
    <div>

      {/* <button onClick={addTextToPDF}>Add Text to PDF</button> */}
      <button onClick={savePDF}>Download PDF</button>
    </div>
  );
};

export default Text;
