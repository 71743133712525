import React, { useEffect, useState } from "react";
import axios from "axios";

import { Link, useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";

import { Menu } from "../../header/Header";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";


const Circulars = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const circulartypes = ["select type","Type 1", "Type 2", "Type 3", "Type 4", "Type 5"];
  if (!token) {
    navigate("/");
  }
  const [circulars, setCirculars] = useState([]);
  const fetchCirculars = () => {
    axios
      .get(`${api_url}/circulars/getListofCirculars`)
      .then((res) => {
        let data = res.data.result;
        setCirculars(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchCirculars();
  }, []);

  return (
    <>
      <div className=" my-1 mx-5 p-1">
        <div className="d-block overflow-auto ">
          <div className="d-inline-block align-middle">
            <Menu link="/hrdash" />
          </div>

          <div className="d-inline-block p-1 float-xxl-end float-xl-end float-md-end float-sm-end">
            <Link to="/cir-form">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<AddIcon />}
              // data-bs-toggle="modal"
              // data-bs-target="#circulartypemodal"
            >
              Send a Circular
            </Button>
            </Link>
          </div>
        </div>
        <div className="table-view-container-wrapper">
          <div className="p-2 mt-2 mb-4 d-flex shadow fw-bold justify-content-center">
            CIRCULARS
          </div>
          <div className="">
            <table className="table table-hover table-responsive">
              <thead>
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">To</th>
                  <th scope="col">CC</th>
                  <th scope="col">Subject</th>
                  <th scope="col">Message</th>
                  <th scope="col">Date</th>
                  <th scope="col">Sent Status</th>
                </tr>
              </thead>
              <tbody className="table-body-font-size">
                {circulars.map((item, index) => (
                  <tr key={index}>
                    <td className="py-0">{index + 1}</td>
                    <td className="py-0">{item.AddressTO}</td>
                    <td className="py-0">{item.AddressCC}</td>
                    <td className="py-0">{item.MailSubject}</td>
                    {/* <td className="py-0">{item.MailMessage}</td> */}
                    <td className="py-0" dangerouslySetInnerHTML={{ __html: item.MailMessage }}></td>
                    <td className="py-0">{item.formatted_date}</td>
                    {/* <td className="py-0">{<Checkmark size="medium" />}</td> */}
                    <td className="py-0">
        {item.EmailSentInd ? (
          <div style={{
            backgroundColor: 'green',
            color: 'white',
            borderRadius: '50%',
            textAlign: 'center',
            padding: '0.5rem',
            display: 'inline-block',
          }}>
            <CheckIcon style={{ fontSize: 24 }} />
          </div>
          
        ) : (
         
          <div style={{
            backgroundColor: 'red',
            color: 'white',
            borderRadius: '50%',
            textAlign: 'center',
            padding: '0.5rem',
            display: 'inline-block',
          }}>
            <CancelIcon style={{ fontSize: 24 }} />
          </div>
        )}
      </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div
          className="modal fade"
          id="circulartypemodal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="circulartypemodal"
          aria-hidden="true"
        >
          <div className="modal-dialog ">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5">
                  Choose the type of circular
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col">
                      <div className="row mb-3">
                        <div className="col-2">
                          <label className="form-label" htmlFor="to">
                            Circular Type:
                          </label>
                        </div>
                        <div className="col-10">
                          {/* <Link to="/cir-form" > */}
                          <select
                            // onChange={(e) => {
                            //   handleDropdownchange(e);
                            // }}
                            // value={employee.Department || ""}
                            name="department"
                            className="form-input-select"
                            id="department"
                            aria-label=".form-select-sm example"
                            required
                            // data-bs-dismiss="modal" 
                            // data-bs-dismiss="modal"
                            // aria-label="Close"
                          >
                            {/* <option value="">Select circular type</option> */}
                            {circulartypes.map((item, index) => (
                              <option 
                              
                              key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          {/* </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="d-flex justify-content-end">
                  <div className="me-4">
                    <Button
                      // type="submit"
                      type="button"
                      variant="contained"
                      color="primary"
                      // endIcon={<SendIcon />}
                      // onClick={()=>{
                      //   navigate("/cir-form",{ state: { circularType: circularType } });
                      // }}
                      // data-bs-dismiss="modal"
                    >
                      Send
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Circulars;
