import React, { useState, useEffect } from "react";
import axios from "axios";
import Loader from "../../../Loader";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import "./TestOffer.css";
import { FaEye } from "react-icons/fa6";
import { fetchCurrentDateTime } from "../../../commonModules/CommonFunctions";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import { PDFDocument, rgb, StandardFonts } from "pdf-lib";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const inchesToPoints = (inches) => inches * 72;

const RecruitmentOfferList = ({ mode, recid }) => {
  const [loading, setLoading] = useState(true);
  const [offerDetails, setOfferDetails] = useState("");
  const [offerList, setOfferList] = useState([]);
  const [htmlContent, setHtmlContent] = useState(""); // Store the generated HTML here

  //Final offer letter states
  // const [offerRefNo, setOfferRefNo] = useState(null);
  const [viewPdf, setViewPdf] = useState(null);
  const [MailAttachments, setMailAttachments] = useState(null);

  const labels = ["S.No", "Total Fixed Salary", "Employee Status", ""];
  const { date } = fetchCurrentDateTime();

  const navigate = useNavigate();
  const api_url = process.env.REACT_APP_API_URL;

  const getDraftOfferDetails = async (id) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${api_url}/recruitment/recruitment-master/edit-recruitment-details/${id}`
      );
      const response = res.data.data;
      console.log("response from api", response);
      setOfferDetails(response);

      if (res.data.success === true) {
        setLoading(false);
      }
    } catch (err) {
      console.log("Error fetching Draft Offer Details", err);
      setLoading(true);
    }
  };

  const generateNonOmaniHtmlContent = async () => {
    let content = `<p><b><u>Draft Offer letter:</u></b></p>`;

    // Compensation
    content += `<p>1. Compensation:</p>`;
    if (offerDetails?.Basic) {
      content += `<p><span class="form-label" style="padding-left: 100px;">Basic salary</span> - ${
        offerDetails?.Basic ? offerDetails.Basic : "N/A"
      } OMR</p>`;
    }
    if (offerDetails?.HRA) {
      content += `<p><span style="padding-left: 100px;">Housing & Transport Allowances</span> - ${
        offerDetails?.HRA ? offerDetails.HRA : "N/A"
      } OMR</p>`;
    }
    if (offerDetails?.TA) {
      content += `<p><span style="padding-left: 100px;">Travel Allowance</span> - ${
        offerDetails?.TA ? offerDetails.TA : "N/A"
      } OMR</p>`;
    }
    if (offerDetails?.SA) {
      content += `<p><span style="padding-left: 100px;">Special Allowance</span> - ${
        offerDetails?.SA ? offerDetails.SA : "N/A"
      } OMR</p>`;
    }
    if (offerDetails?.CA) {
      content += `<p><span style="padding-left: 100px;">Car Allowance</span> - ${
        offerDetails?.CA ? offerDetails.CA : "N/A"
      } OMR</p>`;
    }
    if (offerDetails?.DA) {
      content += `<p><span style="padding-left: 100px;">Desert Allowance</span> - ${
        offerDetails?.DA ? offerDetails.DA : "N/A"
      } OMR</p>`;
    }
    if (offerDetails?.FA) {
      content += `<p><span style="padding-left: 100px;">Food Allowance</span> - ${
        offerDetails?.FA ? offerDetails.FA : "N/A"
      } OMR</p>`;
    }

    if (offerDetails?.OA) {
      content += `<p><span class="form-label" style="padding-left: 100px;">Other Allowances</span> - ${
        offerDetails.OA ? offerDetails.OA : "N/A"
      } OMR</p>`;
    }

    content += `<p><span class="form-label" style="padding-left: 100px;">Total Salary</span> - ${
      offerDetails.TotalFixedSalary ? offerDetails.TotalFixedSalary : "N/A"
    } OMR</p>`;

    // Additional details
    content += `<p>2. Status - ${
      offerDetails?.VisaStatus ? offerDetails.VisaStatus : ""
    }</p>`;
    content += `<p>3. Visa - Provided by Company for ${
      offerDetails?.VisaStatus === "Family" ? "Family" : "Self"
    }</p>`;
    content += `<p>4. Medical - Provided by Company for ${
      offerDetails?.VisaStatus === "Family" ? "Family" : "Self"
    }</p>`;
    content += `<p>5. Hours of work - 8 Hours per day/40 hours per week (Friday & Saturday are Holiday).</p>`;
    content += `<p>6. Leave Cycle - 30 days upon completion of 12 months.</p>`;
    content += `<p>8. Gratuity - As per Oman Government Law.</p>`;
    content += `<p>9. Notice period - ${
      offerDetails?.NoticePeriod ? offerDetails.NoticePeriod : ""
    } on either side</p>`;
    content += `<p style="font-size: 16px; font-family: Arial, sans-serif;">Please confirm the above to release the offer letter in a week's time.</p>`;
    content += `<p style="font-size: 16px; font-family: Arial, sans-serif; margin-top:20px; padding-top:10px;">Thanks &amp; Regards,</p>`
    content += `<p style="font-size: 16px; font-family: Arial, sans-serif;">HR - Atlas</p>`;
    return content;
  };

  const generateOmaniHtmlContent = async () => {
    let content = `<p style="font-size: 16px; font-family: Arial, sans-serif;><b><u>Draft Offer letter:</u></b></p>`;

    // Compensation
    content += `<p style="font-size: 16px; font-family: Arial, sans-serif;>1. Compensation:</p>`;
    if (offerDetails?.Basic) {
      content += `<p style="font-size: 16px; font-family: Arial, sans-serif;><span class="form-label" style="padding-left: 100px;">Basic salary</span> - ${
        offerDetails?.Basic ? offerDetails.Basic : "N/A"
      } OMR</p>`;
    }
    if (offerDetails?.HRA) {
      content += `<p style="font-size: 16px; font-family: Arial, sans-serif;><span style="padding-left: 100px;">Housing & Transport Allowances</span> - ${
        offerDetails?.HRA ? offerDetails.HRA : "N/A"
      } OMR</p>`;
    }
    if (offerDetails?.TA) {
      content += `<p style="font-size: 16px; font-family: Arial, sans-serif;><span style="padding-left: 100px;">Travel Allowance</span> - ${
        offerDetails?.TA ? offerDetails.TA : "N/A"
      } OMR</p>`;
    }
    if (offerDetails?.SA) {
      content += `<p style="font-size: 16px; font-family: Arial, sans-serif;><span style="padding-left: 100px;">Special Allowance</span> - ${
        offerDetails?.SA ? offerDetails.SA : "N/A"
      } OMR</p>`;
    }
    if (offerDetails?.CA) {
      content += `<p style="font-size: 16px; font-family: Arial, sans-serif;><span style="padding-left: 100px;">Car Allowance</span> - ${
        offerDetails?.CA ? offerDetails.CA : "N/A"
      } OMR</p>`;
    }
    if (offerDetails?.DA) {
      content += `<p style="font-size: 16px; font-family: Arial, sans-serif;><span style="padding-left: 100px;">Desert Allowance</span> - ${
        offerDetails?.DA ? offerDetails.DA : "N/A"
      } OMR</p>`;
    }
    if (offerDetails?.FA) {
      content += `<p style="font-size: 16px; font-family: Arial, sans-serif;><span style="padding-left: 100px;">Food Allowance</span> - ${
        offerDetails?.FA ? offerDetails.FA : "N/A"
      } OMR</p>`;
    }

    if (offerDetails?.OA) {
      content += `<p style="font-size: 16px; font-family: Arial, sans-serif;><span class="form-label" style="padding-left: 100px;">Other Allowances</span> - ${
        offerDetails.OA ? offerDetails.OA : "N/A"
      } OMR</p>`;
    }

    content += `<p style="font-size: 16px; font-family: Arial, sans-serif;><span class="form-label" style="padding-left: 100px;">Total Salary</span> - ${
      offerDetails.TotalFixedSalary ? offerDetails.TotalFixedSalary : "N/A"
    } OMR</p>`;

    // Additional details
    content += `<p style="font-size: 16px; font-family: Arial, sans-serif;>2. Status - ${
      offerDetails?.VisaStatus ? offerDetails.VisaStatus : ""
    }</p>`;
    content += `<p style="font-size: 16px; font-family: Arial, sans-serif;>3. Visa - Provided by Company for ${
      offerDetails?.VisaStatus === "Family" ? "Family" : "Self"
    }</p>`;
    content += `<p style="font-size: 16px; font-family: Arial, sans-serif;>4. Medical - Provided by Company for ${
      offerDetails?.VisaStatus === "Family" ? "Family" : "Self"
    }</p>`;
    content += `<p style="font-size: 16px; font-family: Arial, sans-serif;>5. Hours of work - 8 Hours per day/40 hours per week (Friday & Saturday are Holiday).</p>`;
    content += `<p style="font-size: 16px; font-family: Arial, sans-serif;>6. Leave Cycle - 30 days upon completion of 12 months.</p>`;
    content += `<p style="font-size: 16px; font-family: Arial, sans-serif;>7. Air Passage - Provided by company from mobilization to yearly return ticket to nearest international airport.</p>`;
    content += `<p style="font-size: 16px; font-family: Arial, sans-serif;>8. Gratuity - As per Oman Government Law.</p>`;
    content += `<p style="font-size: 16px; font-family: Arial, sans-serif;>9. Notice period - ${
      offerDetails?.NoticePeriod ? offerDetails.NoticePeriod : ""
    } on either side</p>`;
    content += `<p style="font-size: 16px; font-family: Arial, sans-serif;">Please confirm the above to release the offer letter in a week's time.</p>`;
    content += `<p style="font-size: 16px; font-family: Arial, sans-serif; margin-top:20px; padding-top:10px;">Thanks &amp; Regards,</p>`;
    content += `<p style="font-size: 16px; font-family: Arial, sans-serif;">HR - Atlas</p>`;
    return content;
  };

  const GenerateHtmlContent = async () => {
    let htmlcontent;
    if (offerDetails.Nationality === "Omani") {
      htmlcontent = await generateOmaniHtmlContent();
    } else {
      htmlcontent = await generateNonOmaniHtmlContent();
    }
    return htmlcontent;
  };

  useEffect(() => {
    getDraftOfferDetails(recid);
  }, []);

  // Add this effect to set the HTML content after offerDetails are loaded
  useEffect(() => {
    const fetchContent = async () => {
      if (offerDetails) {
        const content = await GenerateHtmlContent();
        setHtmlContent(content); // Store the resolved HTML content
      }
    };
    fetchContent();
  }, [offerDetails]); 

  const handleSendDraftOffer = async () => {
    const payload = {
       AddressTO: offerDetails.PersonalEmailID,
      AddressCC: "raman@atlas-om.com",
      MailSubject: "Draft Offer Letter",
      htmlcontent: htmlContent, 
    };

    try {
      const response = await axios.post(
        `${api_url}/recuritment/offer-letters/send-draft-letter`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.success === true) {
        toast.success("Draft Offer sent Successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Failed to send draft offer:", error);
      toast.error(
        "Draft Offer Failed to send. Please contact the administrator...!",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
        }
      );
    }
  };

  //---------------------Final Offer Letter begins--------------------------------------------------------------

  useEffect(() => {
    // Cleanup on unmount
    return () => {
      if (viewPdf) {
        URL.revokeObjectURL(viewPdf);
      }
    };
  }, [viewPdf]);

  const getFinalOfferTemplate = async (filename) => {
    try {
      const response = await axios.get(
        `${api_url}/recruitment/recrutiment-master/get-final-offer-template/${filename}`,
        { responseType: "blob" }
      );

      if (response.status === 200) {
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        // setPdfFile(pdfBlob);
        const OfferNumber = await getOfferNumberPerYear();
        // setOfferRefNo(OfferNumber);
        // setOfferRefNo(prevRefNo => {
        //   const newOfferRefNo = OfferNumber || prevRefNo;
        //   return newOfferRefNo; 
        // })
        return pdfBlob;
      } else {
        console.error("Failed to fetch PDF:", response.statusText);
      }
    } catch (err) {
      console.error("Error fetching PDF:", err);
    }
    return null; 
  };
  
  const getOfferNumberPerYear = async()=>{
    try {
      const response = await axios.get(
        `${api_url}/recuritment/offer-letters/get-offer-number`
      );

      if (response.status === 200) {
           return response.data.RefNo;
      } else {
        console.error("Failed to fetch PDF:", response);
      }
    } catch (err) {
      console.error("Error fetching PDF:", err);
    }
    return null; 
  }

  const addTextToPDF = async () => {
    const filename =
      offerDetails.Nationality === "Omani"
        ? "Offer Letter - Omani"
        : "Offer Letter - Non Omani";
    const pdfBlob = await getFinalOfferTemplate(filename);
    if (!pdfBlob) {
      console.error("PDF file is missing!");
      return null;
    }
    // Read the PDF file
    const existingPdfBytes = await pdfBlob.arrayBuffer();
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const page = pdfDoc.getPages()[0];

    // Load the font
    const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold); // Using bold font

    // coordinates in inches
    const RefNoCoords = {x:1.18, y: 1.32};
    const EmpNameCoords = { x: 0.80, y: 1.93 };
    const DateCoords = { x: 6.30, y: 1.33 };
    const positionCoords = { x: 2.92, y: 3.765 };
    const reportingToCoords = { x: 2.92, y: 4.045 };
    const totalsalaryCoords = { x: 5.8, y: 4.303 };
    const basicsalaryCoords = { x: 3.96, y: 4.59 };
    const allowanceCoords = { x: 5.65, y: 4.59 };
    const desginationCoords = { x: 5.5, y: 2.58 }; //2.65
    const visaCoords = { x: 2.92, y: 5.14 };
    const medicalCoords = { x: 2.92, y: 4.87 };

    const OfferNumber = await getOfferNumberPerYear();
    const offerno = OfferNumber ? `AIEC-HR-${OfferNumber}/24`:"AIEC-HR-0----/24";
    page.drawText(offerno,{
      x:inchesToPoints(RefNoCoords.x),
      y:page.getHeight() - inchesToPoints(RefNoCoords.y),
      size: 10,
      color: rgb(0, 0, 0)
    })
    
    // const Date = updateDate();
    page.drawText(date, {
      x: inchesToPoints(DateCoords.x),
      y: page.getHeight() - inchesToPoints(DateCoords.y),
      size: 10,
      // font: font,
      color: rgb(0, 0, 0),
    });
    //EmpName Text
    const concat_name = `${offerDetails.FirstName.trim()} ${offerDetails.LastName.trim()}`;
    const title = `${offerDetails.Gender.trim() === "F" ? "Ms. " : "Mr. "}`;
    const EmpName = `Dear  ${title}${concat_name}`;
    page.drawText(EmpName, {
      x: inchesToPoints(EmpNameCoords.x),
      y: page.getHeight() - inchesToPoints(EmpNameCoords.y),
      size: 10,
      font: boldFont,
      color: rgb(0, 0, 0),
    });

    // Draw underline for EmpName text
    page.drawLine({
      start: {
        x: inchesToPoints(EmpNameCoords.x),
        y: page.getHeight() - inchesToPoints(EmpNameCoords.y) - 2, // Adjust for underline position
      },
      end: {
        x: inchesToPoints(EmpNameCoords.x) + 10 * EmpName.length * 0.6, // Length of the underline
        y: page.getHeight() - inchesToPoints(EmpNameCoords.y) - 2,
      },
      thickness: 0.7,
      color: rgb(0, 0, 0),
    });

    //Designation Text
    const Designation = offerDetails.Designation.trim();
    page.drawText(Designation, {
      x: inchesToPoints(desginationCoords.x),
      y: page.getHeight() - inchesToPoints(desginationCoords.y),
      size: 10,
      font: boldFont,
      color: rgb(0, 0, 0),
    });

    // Draw the text for position
    const positionText = offerDetails.Designation.trim();
    page.drawText(positionText, {
      x: inchesToPoints(positionCoords.x),
      y: page.getHeight() - inchesToPoints(positionCoords.y),
      size: 10,
      // font: font,
      color: rgb(0, 0, 0),
    });

    // Draw the text for reportingTo
    const reportingToText = offerDetails.ReportingManagerName;
    page.drawText(reportingToText, {
      x: inchesToPoints(reportingToCoords.x),
      y: page.getHeight() - inchesToPoints(reportingToCoords.y),
      size: 10,
      color: rgb(0, 0, 0),
    });

    //Total Salary Text
    const totalSalaryText = offerDetails.TotalFixedSalary.toString();
    page.drawText(totalSalaryText, {
      x: inchesToPoints(totalsalaryCoords.x),
      y: page.getHeight() - inchesToPoints(totalsalaryCoords.y),
      size: 10,
      color: rgb(0, 0, 0),
    });

    //Basic Salary Text
    const basicSalaryText = offerDetails.Basic.toString();
    page.drawText(basicSalaryText, {
      x: inchesToPoints(basicsalaryCoords.x),
      y: page.getHeight() - inchesToPoints(basicsalaryCoords.y),
      size: 10,
      color: rgb(0, 0, 0),
    });

    //Allowances Text
    const allowancesText = (
      offerDetails.TotalFixedSalary - offerDetails.Basic
    ).toString();
    page.drawText(allowancesText, {
      x: inchesToPoints(allowanceCoords.x),
      y: page.getHeight() - inchesToPoints(allowanceCoords.y),
      size: 10,
      color: rgb(0, 0, 0),
    });

    //Medical Text
    const MedicalText =
      offerDetails.Medical === "Self"
        ? "Provided by company for Self."
        : "Provided by company for Self & Family.";
    page.drawText(MedicalText, {
      x: inchesToPoints(medicalCoords.x),
      y: page.getHeight() - inchesToPoints(medicalCoords.y),
      size: 10,
      // font:calibriFont,
      color: rgb(0, 0, 0),
    });

    //VisaText
    if (offerDetails.Nationality.trim() !== "Omani") {
      const VisaText =
        offerDetails.VisaStatus === "Self"
          ? "Provided by company for Self."
          : "Provided by company for Self & Family.";
      page.drawText(VisaText, {
        x: inchesToPoints(visaCoords.x),
        y: page.getHeight() - inchesToPoints(visaCoords.y),
        size: 10,
        // font:calibriFont,
        color: rgb(0, 0, 0),
      });
    }

    return pdfDoc;
  };

  const handleGenerateFinalOffer = async () => {
    const modifiedPdf = await addTextToPDF();
    if (modifiedPdf) {
      const pdfBytes = await modifiedPdf.save(); // Save the modified PDF
      const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setViewPdf(pdfUrl); // Set the URL of the modified PDF
      console.log("PDF Blob Size:", pdfBlob.size);
      setMailAttachments(pdfBlob);
    }
  };

  const handleSendFinalOffer = async () => {
    if (!MailAttachments) {
      toast.error("No pdf to send!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });
      return;
    }
  
    // Create FormData and append the necessary data
    const dataToSend = new FormData();
    dataToSend.append("AddressTO", offerDetails.PersonalEmailID);
    dataToSend.append("AddressCC", "raman@atlas-om.com");
    dataToSend.append("MailSubject", `Offer Letter - ${offerDetails.Designation}`);
    dataToSend.append("MailMessage", "Please find the attached offer letter");
  
    // Dynamically create the filename based on the Designation
    const fileName = `Offer letter - ${offerDetails.Designation}.pdf`;
  
    // Append the file with the new filename
    dataToSend.append("MailAttachment", MailAttachments, fileName);
  
    console.log("Sending data:", dataToSend);
  
    try {
      const response = await axios.post(
        `${api_url}/recuritment/offer-letters/send-final-letter`,
        dataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      if (response.data.success) {
        toast.success("Draft Offer sent successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
        });
      } else {
        toast.error("Failed to send Draft Offer. Please try again.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Failed to send email:", error);
      toast.error("Failed to send Draft Offer. Please contact the administrator.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });
    }
  };
  

  
  const newplugin = defaultLayoutPlugin();

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="container-fluid">
            <div className="d-flex justify-content-end">
              <div className="d-flex">
                <div className="me-3">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    endIcon={<SendIcon />}
                    data-bs-toggle="modal"
                    data-bs-target="#draftofferlettermodal"
                  >
                    Send Draft Offer
                  </Button>
                </div>
                <div>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    endIcon={<SendIcon />}
                    onClick={handleGenerateFinalOffer}
                    data-bs-toggle="modal"
                    data-bs-target="#finalofferlettermodal"
                  >
                    Send Final Offer
                  </Button>
                </div>
              </div>
            </div>
            <div className="table-view-container-wrapper">
              <div className="p-2 d-flex fw-bold shadow justify-content-center blockquote">
                Draft Offer & Negotiations
              </div>
              <table className="table table-hover table-responsive">
                <thead>
                  <tr>
                    {labels.map((item, index) => (
                      <th
                        scope="col"
                        key={index}
                        className="table-header-font-size"
                      >
                        {item}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="table-body-font-size">
                  {offerList.map((item, index) => {
                    const sno = index + 1;
                    return (
                      <tr
                        className="py-0"
                        key={index}
                        onClick={() => {
                          sessionStorage.setItem("RecruitmentID", item.RecruitmentID);
                          navigate("/hr/recruitment-master/stepper", {
                            state: { mode: "edit", recid: item.RecruitmentID },
                          });
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <td className="py-0">{sno}</td>
                        <td className="py-0">{item.TotalFixedSalary}</td>
                        <td className="py-0">{item.Status}</td>
                        <td className="py-0">
                          <Link
                            to="/hr/recruitment-master/stepper"
                            state={{ mode: "edit", recid: item.OfferID }}
                            onClick={() => {
                              sessionStorage.setItem("OfferID", item.OfferID);
                            }}
                          >
                            <FaEye />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {/*Draft Offer Modal */}
          <div
            className="modal fade"
            id="draftofferlettermodal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="draftofferlettermodal"
            aria-hidden="true"
          >
            <div className="modal-dialog ">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5">Draft Offer Letter</h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col">
                        <div className="row mb-3">
                          <div className="col-2">
                            <label className="form-label" htmlFor="to">
                              TO:
                            </label>
                          </div>
                          <div className="col-10">
                            <input
                              type="text"
                              className="form-input-text"
                              name="to"
                              value={offerDetails.PersonalEmailID || ""}
                              id="to"
                              disabled
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-2">
                            <label className="form-label" htmlFor="cc">
                              CC
                            </label>
                          </div>
                          <div className="col-10">
                            <input
                              type="text"
                              className="form-input-text"
                              name="cc"
                              value={"raman@atlas-om.com"}
                              id="cc"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-2">
                            <label className="form-label" htmlFor="to">
                              Subject
                            </label>
                          </div>
                          <div className="col-10">
                            <input
                              type="text"
                              className="form-input-text"
                              name="to"
                              // value={offerDetails.PersonalEmailID}
                              value={"Draft Offer Letter - Atlas International"}
                              id="to"
                              disabled
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-2">
                            <label className="form-label" htmlFor="to">
                              Message:
                            </label>
                          </div>
                          <div className="col-10">
                            <div
                              className="form-input-textarea circular-message-textarea"
                              contentEditable={true}
                              dangerouslySetInnerHTML={{ __html: htmlContent }} // Use the state here
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="d-flex justify-content-end">
                    <div className="me-4">
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        endIcon={<SendIcon />}
                        onClick={handleSendDraftOffer}
                      >
                        Send
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Final Offer Modal */}
          <div
            className="modal fade"
            id="finalofferlettermodal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="finalofferlettermodal"
            aria-hidden="true"
          >
            <div className="modal-dialog ">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5">Final Offer Letter</h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col">
                        <div className="row mb-3">
                          <div className="col-2">
                            <label className="form-label" htmlFor="to">
                              TO:
                            </label>
                          </div>
                          <div className="col-10">
                            <input
                              type="text"
                              className="form-input-text"
                              name="to"
                              value={offerDetails.PersonalEmailID || ""}
                              id="to"
                              // value={formData.to}
                              // onChange={handleChange}
                              disabled
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-2">
                            <label className="form-label" htmlFor="cc">
                              CC
                            </label>
                          </div>
                          <div className="col-10">
                            <input
                              type="text"
                              className="form-input-text"
                              name="cc"
                              // value={offerDetails.ReportingManagerMailID}
                              // value={formData.cc}
                              value={`raman@atlas-om.com`}
                              // onChange={handleChange}
                              id="cc"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-2">
                            <label className="form-label" htmlFor="to">
                              Subject
                            </label>
                          </div>
                          <div className="col-10">
                            <input
                              type="text"
                              className="form-input-text"
                              name="to"
                              value={`Offer Letter - ${offerDetails.Designation}`}
                              // onChange={handleChange}
                              id="to"
                              disabled
                            />
                          </div>
                        </div>

                        {/* <div className="row mb-3">
                          <div className="col-2">
                            <label className="form-label" htmlFor="to">
                              Message:
                            </label>
                          </div>
                          <div className="col-10">
                          <textarea
                              className="form-input-textarea"
                              id="to"
                              rows="3"
                              columns="6"
                              value={formData.message}
                              onChange={handleChange}
                              disabled
                            ></textarea>
                          </div>
                        </div> */}
                        <div className="row mb-3">
                          <div className="col-2">
                            <label className="form-label" htmlFor="to">
                              Final Offer Letter Preview:
                            </label>
                          </div>
                          <div className="col-10">
                            <div className="border pdf-container">
                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                {viewPdf ? (
                                  <Viewer
                                    fileUrl={viewPdf}
                                    plugins={[newplugin]}
                                  />
                                ) : (
                                  <>No PDF</>
                                )}
                              </Worker>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="d-flex justify-content-end">
                    <div className="me-4">
                      <Button
                        // type="submit"
                        type="button"
                        variant="contained"
                        color="primary"
                        endIcon={<SendIcon />}
                        onClick={handleSendFinalOffer}
                      >
                        Send
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </>
      )}
    </>
  );
};

export default RecruitmentOfferList;
