import React, { useEffect, useState } from "react";
import { Menu } from "../../header/Header";
import {useLocation, useNavigate } from "react-router-dom";
import Loader from "../../Loader";
import axios from "axios";

import { nationalities } from "../../../data/nationalities";


import { Button } from "@mui/material";
import "./RecruitmentPlanForm.css";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RecruitmentPlanForm = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const navigate = useNavigate();
  const [formDetails, setFormDetails] = useState({});
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDept, setSelectedDept] = useState("");

  const { reqid, mode } = location.state || {};

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);

  const handleInputChanges = (event, field) => {
    let temp = { ...formDetails };
    temp[field] = event.target.value;
    setFormDetails(temp);
  };


  const getDepartments = async () => {
    try {
      const response = await axios.get(`${api_url}/emp/GetDepartments`);
      let result = response.data.results;
      setDepartments(result);
      setSelectedDept("");
      return result;
    } catch (error) {
      console.error("There was an error fetching the departments!", error);
    }
  };

  const handleDepartmentChange = async (e) => {
    let value = e.target.value;
    if (value === "") {
      setSelectedDept(value);
      setDesignations([]);
    } else {
      const deptId = departments.find(
        (dept) => dept.DepartmentName === e.target.value
      ).DeptID;
      setSelectedDept(value);
      await getDesignations(deptId);
    }
  };

  const getDesignations = (e) => {
    axios
      .get(`${api_url}/emp/GetDesignations/${e}`)
      .then((response) => {
        setDesignations(response.data);
      })
      .catch((err) => {
        console.error("Error fetching Desingations", err);
      });
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      await getDepartments();

      if (mode === "edit") {
        await getPlanDetails(reqid);
      } else if (mode === "add") {
        await getLatestFileNumber();
      }
      setLoading(false)
    };

    fetchData();
  }, [mode, reqid]);

  const InsertPlanDetails = async () => {
    let temp = {
      RefID: formDetails.RefID,
      Department: formDetails.Department,
      Designation: formDetails.Designation,
      Nationality: formDetails.Nationality,
      NoofCount: formDetails.NoofCount,
      CurrentRevision: formDetails.CurrentRevision,
    };
    try {
      const response = await axios.post(
        `${api_url}/hr/recruitment-plan/create-plan-detail`,
        temp,
        {
          headers: {
            "Content-Type": "application/json", 
          },
        }
      );
      return response.data.success;
    } catch (err) {
      console.log("Error:", err);
      return false;
    }
  };

  const getLatestFileNumber = async () => {
    await axios
      .get(`${api_url}/hr/recruitment-plan/get-latest-file-number`)
      .then((res) => {
        const RefID = res.data.RefID;
        const RefIdPrefix = "AEC-RCP-";
        const paddedfileRefIDnumber = RefID.toString().padStart(3, "0");
        const PlanNumber = RefIdPrefix + paddedfileRefIDnumber;
        const temp = { ...formDetails }; 
        temp["RefID"] = RefID;
        temp["CurrentRevision"] = PlanNumber;
     
        setFormDetails(temp);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const handleSubmit = (eve) => {
    eve.preventDefault();
    mode === "add" ? addPlan() : EditPlan(reqid);
  };

  const addPlan = async () => {
    const insert = await InsertPlanDetails();
    navigate("/hr/recruitment-plan-list")
    if (insert === true) {
      toast.success("Recruitment Plan Added Successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });
    } else {
      toast.error("Failed to Save... Please contact Administrator", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });
    }
  };
  const EditPlan = async (id) => {
    const update = await updatePlanDetails(id);
    if(update===true){
      toast.success("Recruitment Plan Updated Successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });
    }
    else{
      toast.error("Updation Failed... Please contact Administrator", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });
    }
    navigate("/hr/recruitment-plan-list")
   
  };


  const getPlanDetails = async (id) => {
    try {
      const res = await axios.get(
        `${api_url}/hr/recruitment-plan/get-document-details/${id}`
      );
      let plan = res.data.result[0];
      // console.log("Fetched Plan Details:", plan); // Log the entire plan object
      let depts = await getDepartments();
      let dept_id = depts.find(
        (dept) => dept.DepartmentName === plan.Department
      ).DeptID;
      await getDesignations(dept_id);
      setSelectedDept(plan.Department);
      setFormDetails(plan);
      
    } catch (err) {
      console.log(err);
    }
  };

  const updatePlanDetails = async (eid) => {
    const payload = {
      Department: formDetails.Department,
      Designation: formDetails.Designation,
      Nationality: formDetails.Nationality,
      NoofCount: formDetails.NoofCount,
      CurrentRevision: formDetails.CurrentRevision,
    };
    try{
      const response =  await axios
      .put(
        `${api_url}/hr/recruitment-plan/update-document-details/${eid}`,
        payload
      )
      return response.data.success;
    }
      catch(error) {
        console.error("There was an error updating!", error);
        return false;
      };

  };


  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };


  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <Menu link="/hr/recruitment-plan-list" />
          </div>
          <div className="emp-form d-block">
            {/* Form Starts */}
            <form
              className=""
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              {/* <div className="emp-form-save-button">
            {mode === "edit" ? (
              <RedContainedButton
                variant="contained"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  handleDelete(reqid);
                }}
                className="m-2"
              >
                Delete
              </RedContainedButton>
            ) : (
              ""
            )}
          </div> */}
              <div className="container-fluid">
                <div className="container p-3 form-wrapper border border-secondary">
                  <div className="row sub-wrapper mb-2 ">
                    <h5 className="sub-wrapper-heading">Recruitment Plan</h5>
                    <div className="col-12 col-lg-6 col-xl-6 col-xxl-6">
                      {/* <label className="form-label" htmlFor="filename">
                    File Name<span className="required"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-input-text"
                    id="filename"
                    placeholder=""
                    value={formDetails.FormName || ""}
                    maxLength="150"
                    onChange={(e) => {
                      handleInputChanges(e, "FormName");
                    }}
                    required
                  /> */}

                      <label className="form-label" htmlFor="currentrevision">
                        Current Revision
                      </label>
                      <input
                        type="text"
                        className="form-input-text"
                        id="currentrevision"
                        placeholder=""
                        value={formDetails.CurrentRevision || ""}
                        maxLength="150"
                        onChange={(e) => {
                          handleInputChanges(e, "CurrentRevision");
                        }}
                        required
                        disabled
                      />

                      <label className="form-label" htmlFor="department">
                        Department<span className="required"> *</span>
                      </label>
                      <select
                        onChange={(e) => {
                          handleInputChanges(e, "Department");
                          handleDepartmentChange(e);
                        }}
                        value={formDetails.Department || ""}
                        name="department"
                        className="form-input-select"
                        id="department"
                        aria-label=".form-select-sm example"
                        required
                      >
                        <option value="">Select Department</option>
                        {departments.map((dept) => (
                          <option key={dept.DeptID} value={dept.DepartmentName}>
                            {dept.DepartmentName}
                          </option>
                        ))}
                      </select>

                      <label className="form-label" htmlFor="designation">
                        Designation<span className="required"> *</span>
                      </label>
                      <select
                        className="form-input-select"
                        onChange={(e) => {
                          handleInputChanges(e, "Designation");
                        }}
                        id="designation"
                        name="designation"
                        value={formDetails.Designation || ""}
                        disabled={!selectedDept}
                        required
                      >
                        <option value="">Select Designation</option>
                        {designations.map((item) => (
                          <option
                            key={item.DesigID}
                            value={item.DesignationName}
                          >
                            {item.DesignationName}
                          </option>
                        ))}
                      </select>

                      <label className="form-label" htmlFor="nationality">
                        Nationality<span className="required"> *</span>
                      </label>
                      <select
                        name="nationality"
                        className="form-input-select"
                        aria-label=".form-select-sm example"
                        onChange={(e) => {
                          handleInputChanges(e, "Nationality");
                        }}
                        value={formDetails.Nationality || ""}
                        required
                      >
                        <option value="">Select Nationality</option>
                        {
                          nationalities.map((item,index)=>(
                            <option key={index} value={item}>{item}</option>
                          ))
                        }
                        
                        {/* <option value="Omani">Omani</option>
                        <option value="Bangladeshi">Bangladeshi</option>
                        <option value="Egyptian">Egyptian</option>
                        <option value="Filipino">Filipino</option>
                        <option value="Indian">Indian</option>
                        <option value="Irani">Irani</option>
                        <option value="Iraqi">Iraqi</option>
                        <option value="Pakistani">Pakistani</option>
                        <option value="Syrian">Syrian</option>
                        <option value="Sudanese">Sudanese</option>
                        <option value="Turkish">Turkish</option> */}
                      </select>

                      <label className="form-label" htmlFor="noofcount">
                        No of Count
                      </label>
                      <input
                        type="number"
                        className="form-input-text"
                        id="noofcount"
                        placeholder=""
                        value={formDetails.NoofCount}
                        maxLength="150"
                        onChange={(e) => {
                          handleInputChanges(e, "NoofCount");
                        }}
                        // required
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                    <div className="d-flex justify-content-end mt-2">
                      <Button type="submit" variant="contained" color="primary">
                        Save Details
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default RecruitmentPlanForm;
