import React, { useState, useEffect, useRef } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import "./EmployeeOverview.css";
import axios from "axios";
import Profilepicicon from "../../../assets/Profile-picture.jpg";
import { Menu } from "../../header/Header";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DescriptionIcon from "@mui/icons-material/Description";
import SendIcon from "@mui/icons-material/Send";
import Loader from "../../Loader";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EmployeeOverview = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const api_url = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const { mode, empID } = location.state || {};
  const inputRef = useRef(null);
  const [image, setImage] = useState("");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const userData = JSON.parse(sessionStorage.getItem("CurrentUserData"));

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
    getUserDetails(empID);
  }, []);

  const HandleImageClick = () => {
    inputRef.current.click(); // Trigger file input click
  };

  const HandleImageChange = async (event) => {
    const file = event.target.files[0];
    const validTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (file) {
      if (validTypes.includes(file.type)) {
        setImage(URL.createObjectURL(file)); // Display selected image
        await uploadProfilePicture(file);
      } else {
        event.target.value = null;
        alert(
          "Invalid file type. Please select an image file (PNG, JPEG, JPG)."
        );
      }
    } else {
      alert("Please re-upload the image");
    }
  };

  const divstyle = { width: "80%" };
  const divstyle1 = { width: "50%" };

  const getUserDetails = async (eid) => {
    try {
      const res = await axios.get(`${api_url}/emp/getoverview/${eid}`);
      setImage(res.data.imageUrl);
      setData(res.data.list[0]);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const uploadProfilePicture = async (file) => {
    try {
      const formData = new FormData();
      formData.append("Profile-Picture", file);
      formData.append("id", empID); // Assuming empID is needed on the server side

      const response = await axios.post(
        `${api_url}/emp/uploadprofilepicture/${empID}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    }
  };

 

  // const htmlContent = `
  // <p style="font-size: 16px; line-height: 1.5; margin: 0;"><u>Dear ${data.FirstName} ${data.LastName}, </u></p>
  // <p style="font-size: 16px; line-height: 1.5; margin: 20px 0;">Please find the below information of ERP Link & Credentials:</p>
  // <p style="font-size: 16px; line-height: 1.5; margin: 20px 0;"><span>ERP Login Link:</span>&nbsp; &nbsp;<a href="https://atlas-om.com"><i>https://atlas-om.app</i></a></p>
  // <p style="font-size: 16px; line-height: 1.5; margin: 5px 0;">Username: <strong>${empID}</strong></p>
  // <p style="font-size: 16px; line-height: 1.5; margin: 5px 0 13px 0;">Password: &nbsp;<strong>ERP${empID.slice(3)}</strong></p>
  // <p style='font-size: 16px; line-height: 1.5; margin: 35px 0 13px 0;'><u><strong>NOTE:</strong></u></p>
  // <p style="font-size: 16px; line-height: 1.5; margin: 5px 0;">The credentials provided are the system defaults. Users must reset their password upon initial login by following these steps:</p> 
  // <i style="font-size: 16px;">Log in with the default credentials. Then Hover over the User Profile Icon in the top right corner. In the popup menu, you'll find the option to change your password. Click on it to update your password.</i>
  // <p style="font-size: 16px; line-height: 1.5; margin: 20px 0 0 0;">Thanks & Regards,</p>
  // <p style="font-size: 16px; line-height: 1.5; margin: 0;">${userData.EmpName}</p>
  
  // `;

  // const htmlContent = `
  // <p style="font-size: 16px; line-height: 1.5; margin: 0;"><u>Dear ${data.FirstName} ${data.LastName}, </u></p>
  // <p style="font-size: 16px; line-height: 1.5; margin: 20px 0;">Please find the below information of ERP Link & Credentials:</p>
  // <p style="font-size: 16px; line-height: 1.5; margin: 20px 0;"><span>ERP Login Link:</span>&nbsp; &nbsp;<a href="https://atlas-om.com"><i>https://atlas-om.app</i></a></p>
  // <p style="font-size: 16px; line-height: 1.5; margin: 5px 0;">Username: <strong>${empID}</strong></p>
  // <p style="font-size: 16px; line-height: 1.5; margin: 5px 0 13px 0;">Password: &nbsp;<strong>ERP${empID.slice(3)}</strong></p>
  // <p style='font-size: 16px; line-height: 1.5; margin: 35px 0 13px 0;'><u><strong>NOTE:</strong></u></p>
  // <p style="font-size: 16px; line-height: 1.5; margin: 5px 0;">The credentials provided are the system defaults. Users must reset their password upon initial login by following these steps:</p> 
  // <i style="font-size: 16px;">Log in with the default credentials. Then Hover over the User Profile Icon in the top right corner. In the popup menu, you'll find the option to change your password. Click on it to update your password.</i>
  // <p style="font-size: 16px; line-height: 1.5; margin: 20px 0 0 0;">Thanks & Regards,</p>
  // <p style="font-size: 16px; line-height: 1.5; margin: 0;">Admin - ERP</p>
  
  // `;

  const htmlContent = `
  <p style="font-size: 16px; line-height: 1.5; margin: 0;"><u>Dear ${data.FirstName} ${data.LastName}, </u></p>
  <p style="font-size: 16px; line-height: 1.5; margin: 20px 0;">Please find the below information of ERP Link & Credentials:</p>
  <p style="font-size: 16px; line-height: 1.5; margin: 20px 0;"><span>ERP Login Link:</span>&nbsp; &nbsp;<a href="https://atlas-om.app" target="_blank" rel="noopener noreferrer"><i>https://atlas-om.app</i></a></p>
  <p style="font-size: 16px; line-height: 1.5; margin: 5px 0;">Username: <strong>${empID}</strong></p>
  <p style="font-size: 16px; line-height: 1.5; margin: 5px 0 13px 0;">Password: &nbsp;<strong>ERP${empID.slice(3)}</strong> (Initial Password)</p>
  <i style="font-size: 16px; line-height: 1.5; margin: 30px 0 0 0;">Please find the attached PDF, which contains the usage instructions.</i> 
  <p style='font-size: 16px; line-height: 1.5; margin: 35px 0 13px 0;'><u><strong>NOTE:</strong></u></p>
  <p style="font-size: 16px; line-height: 1.5; margin: 5px 0;">The credentials provided are the system defaults. Users must reset their password upon initial login by following these steps:</p> 
  <i style="font-size: 16px;">Log in with the default credentials. Then Hover over the User Profile Icon in the top right corner. In the popup menu, you'll find the option to change your password. Click on it to update your password.</i>
  <p style="font-size: 14px; line-height: 1.5; margin:15px 0 0 0; color:red;">If you face any issues, please contact <i>raman@atlas-om.com</i></p>`;

  
  const handleSendDraftOffer = async () => {

  const dataToSend = {
      to: data.OfficialEmailID,
      // to: "raman@atlas-om.com",
      // subject: `ERP Credentials Reset - ${empID}`,
      subject: `ERP Credentials - ${empID}`,
      Message: htmlContent
      }
 

  try {
    const response = await axios.post(
      `${api_url}/user/resetpassword/${empID}`,
      dataToSend,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.data.success) {
      // toast.success("Password resetted and and mail was sent Successfully!", {
        toast.success("Credentials Resetted and mail was sent Successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });
    } else {
      // toast.error("Failed to send the resetted password. Please Contact adminstrator.", {
        toast.error("Failed to Reset and sending credential. Please Contact adminstrator.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });
    }
  } catch (error) {
    console.error("Failed to send email:", error);
    toast.error(
      "Failed to send the resetted password. Please Contact adminstrator.",
      {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      }
    );
  }
};

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="employee-dashboard container g-3">
          <div className="d-flex justify-content-between mb-4">
            <Menu link="/empmgmt" />
            <div className="">
              <div
                className="modal fade"
                id="resetpasswordmodal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="resetpasswordmodal"
                aria-hidden="true"
              >
                <div className="modal-dialog ">
                  <div className="modal-content">
                    <div className="modal-header">
                      {/* <h1 className="modal-title fs-5">ERP Credentials Reset </h1> */}
                      <h1 className="modal-title fs-5">Atlas ERP Credentials </h1>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col">
                            <div className="row mb-3">
                              <div className="col-2">
                                <label className="form-label" htmlFor="to">
                                  TO:
                                </label>
                              </div>
                              <div className="col-10">
                                <input
                                  type="text"
                                  className="form-input-text"
                                  name="to"
                                  // value={offerDetails.PersonalEmailID || ""}
                                  id="to"
                                  value={data?.OfficialEmailID}
                                  // onChange={handleChange}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-2">
                                <label className="form-label" htmlFor="to">
                                  Subject
                                </label>
                              </div>
                              <div className="col-10">
                                <input
                                  type="text"
                                  className="form-input-text"
                                  name="to"
                                  // value={`ERP Credentials Reset - ${empID}`}
                                  value={`ERP Credentials - ${empID}`}
                                  // onChange={handleChange}
                                  id="to"
                                  disabled
                                />
                              </div>
                            </div>

                             <div className="row mb-3">
                              <div className="col-2">
                                <label
                                  className="form-label"
                                  htmlFor="htmlContent"
                                >
                                  HTML Content
                                </label>
                              </div>
                              {/* Display HTML content in a div */}
                              <div className="col-10">
                                <div
                                  id="htmlContent"
                                  className="form-input-textarea"
                                  dangerouslySetInnerHTML={{
                                    __html: htmlContent,
                                  }}
                                  disabled
                                />
                           
                              </div>
                            </div>
                                                   </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <div className="d-flex justify-content-end">
                        <div className="me-4">
                          <Button
                            // type="submit"
                            type="button"
                            variant="contained"
                            color="primary"
                            endIcon={<SendIcon />}
                            onClick={handleSendDraftOffer}
                            data-bs-dismiss="modal"
                        aria-label="Close"
                          >
                            Send
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <Link to="/empdocs" className="hr-documents-button" state={{ mode: mode, empID: empID }}> */}
              <Button
                className="me-3"
                type="submit"
                variant="contained"
                color="success"
                data-bs-toggle="modal"
                data-bs-target="#resetpasswordmodal"
                endIcon={<SendIcon />}
              >
                Reset Password & Send Credentials
              </Button>
              {/* </Link> */}
              <Link
                to="/empdocs"
                className="hr-documents-button"
                state={{ mode: mode, empID: empID }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  endIcon={<DescriptionIcon />}
                >
                  Documents
                </Button>
              </Link>
              <Link to="/empform" state={{ mode: mode, empID: empID }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<EditIcon />}
                >
                  Edit
                </Button>
              </Link>
            </div>
          </div>

          <div className="row g-3">
            <div className="col-2 box-div">
              <div onClick={HandleImageClick}>
                <img
                  src={image || Profilepicicon} // Display selected image or default
                  className="profile-img"
                  alt="profile-img"
                />
                <input
                  type="file"
                  className="d-none p-0"
                  id="inputprofilepic"
                  onChange={HandleImageChange}
                  ref={inputRef}
                  name="profilepic"
                  accept="image/png, image/jpeg, image/jpg"
                />
              </div>
            </div>
            <div className="col-10 details-div">
              <div className="row ">
                <div className="col-4 col-md-2">
                  <p className="form-label">First Name </p>
                </div>
                <div className="col-4 col-md-3">
                  {/* <input type="text" className="form-input-text" name="fname" id="fname" placeholder="Sample Text" disabled/> */}
                  <p className="fs-7 m-0">{data.FirstName}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <p className="form-label">Last Name </p>
                </div>
                <div className="col-3">
                  {/* <input type="text" name="lname" className="form-input-text" id="lname" placeholder="Sample Text" disabled /> */}
                  <p className="fs-7 m-0">{data.LastName}</p>
                </div>
              </div>
              <div className="row ">
                <div className="col-2">
                  <p className="form-label">Department</p>
                </div>
                <div className="col-3">
                  {/* <input type="text" name="department" className="form-input-text" id="department"  placeholder="Sample Text"/> */}
                  <p className="fs-7 m-0">{data.Department}</p>
                </div>
              </div>
              <div className="row ">
                <div className="col-2">
                  <p className="form-label">Designation</p>
                </div>
                <div className="col-3">
                  {/* <input type="text" name="designation" className="form-input-text" id="designation" placeholder="Sample Text" /> */}
                  <p className="fs-7 m-0">{data.Designation}</p>
                </div>
              </div>
              <div className="row ">
                <div className="col-2">
                  <p className="form-label">Email ID</p>
                </div>
                <div className="col-3">
                  {/* <input type="email" className="form-input-email" name="emailid"  id="emailid" placeholder="Sample Text" disabled /> */}
                  <p className="fs-7 m-0">{data.OfficialEmailID}</p>
                </div>
              </div>
              <div className="row ">
                <div className="col-2">
                  <p className="form-label">Mobile Number</p>
                </div>
                <div className="col-3">
                  {/* <input type="number" className="form-input-number" name="mobilenumber" id="mobilenumber" min={0} onKeyDown={handleKeyDown} onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })} /> */}
                  <p className="fs-7 m-0">{data.OfficialMobileNumber}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-12">
              <div className="row g-3 mt-2">
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="emp-dash-card">
                    <div className="card-category">Length of Employment</div>
                    <div className="card-content">
                      <div className="card-title"> 9 Years</div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="emp-dash-card">
                    <div className="card-category">Absences</div>
                    <div className="card-content">
                      <div className="card-title"> 7 Days</div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="p-2 border  emp-dash-card">
                    <div className="card-category">Remaining Annual Leave</div>
                    <div className="card-content">
                      <div className="card-title"> 22 Days</div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="p-2 border  emp-dash-card">
                    <div className="fs-5 mb-2">Leave Schedule</div>
                    <div className="mt-1">
                      <p>AL - 15-08-2024 - 15-09-2024</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-3 mt-2">
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="p-2 border  emp-dash-card">
                    <div className="fs-5">Trainings Attended</div>
                    <div className="mt-1">
                      <p>HSE</p>
                      <p>H2S</p>
                      <p>ERP Training</p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="p-2 border emp-dash-card">
                    <div className="fs-5 fw-semibold">Active Jobs</div>
                    <div className="mt-1">
                      <p>Project 1</p>
                      <p>Project 2</p>
                      <p>Project 3</p>
                      <p>Project 8</p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="p-2 border emp-dash-card">
                    <div className="fs-5 fw-semibold">Skill set rating</div>
                    <div className="">
                      <div className="d-flex align-items-center">
                        <div className="me-2">
                          <p>AutoCAD</p>
                        </div>

                        <div className="progress w-100">
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            aria-label="Example with label"
                            style={divstyle}
                            aria-valuenow="80"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            80%
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="me-2">
                          <p>SolidWorks</p>
                        </div>
                        <div className="progress w-100">
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            aria-label="Example with label"
                            style={divstyle1}
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            50%
                          </div>
                        </div>
                      </div>
                      {/* <div>
                  <div>
                    <p>Civil 3D</p>
                  </div>
                  <div className="progress">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      aria-label="Example with label"
                      style={divstyle}
                      aria-valuenow="37"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      37%
                    </div>
                  </div>
                </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="p-2 border emp-dash-card">
                    <div className="fs-5 fw-semibold">
                      Language known / Fluency
                    </div>
                    <div className="">
                      <p>Arabic</p>
                      <p>English</p>
                      <p>Hindi</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EmployeeOverview;
