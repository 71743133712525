import React, { useState, useEffect } from "react";
import axios from "axios";
import Loader from "../../Loader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa6";
import { Menu } from "../../header/Header";
import { Button } from "@mui/material";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BaseLocationApprovalList = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const [mapSrc, setMapSrc] = useState("");
  const userData = JSON.parse(sessionStorage.getItem("CurrentUserData"));
  const AtlasEmpID = userData.AtlasEmpID;

  // const labels = ["S.No","AEC ID","Employee Name","Department","Designation","Leave Type","Status",""]
  const labels = [
    "S.No",
    "AEC ID",
    "Employee Name",
    "Submitted Date",
    "Status",
    "",
  ];

  const [BaseLocationList, setBaseLocationList] = useState([]);
  const [BaseLocationItem, setBaseLocationItem] = useState([]);
  // const [listtype, setListType] = useState("P");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  //Who is opening the form
  useEffect(() => {
    if (!token) {
      navigate("/");
      return;
    }

   
    fetchBaseLocationList();
  }, []);

  const fetchBaseLocationList = async () => {
    try {
      const response = await axios.get(
        `${api_url}/punch/baselocationapprovallist/${AtlasEmpID}`
      );
      const result = response.data.data;
      setBaseLocationList(result);
    } catch (error) {
      console.log(error);
      setLoading(true);
    } finally {
      setLoading(false);
    }
  };

  // const link = userData.AtlasEmpID==="AEC002"?"/hrdash":"/requestdashboard";

  const handleRowClick = async (id) => {
    try {
      // console.log("params", AtlasEmpID, "sort-type", listtype);
      const response = await axios.get(
        `${api_url}/baselocation-approval/baselocationdetail/${id}`
      );
      const result = response.data.data[0];
      const newMapSrc = `https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1376.5732871228681!2d${result.BaseLong}!3d${result.BaseLat}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2som!4v1727679743582!5m2!1sen!2som`;
      setMapSrc(newMapSrc);
      setBaseLocationItem(result);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(true);
    }
  };
  const handleApprovalItemUpdate = async (id, payload) => {
    try {
      const response = await axios.put(
        `${api_url}/baselocation-approval/updatelocationapprovalitem/${id}`,
        payload
      );
      await fetchBaseLocationList();
      return response.data.success;
      
    } catch (error) {
      console.error("There was an error updating the Request!", error);
      return false;
    }
  };

  const handleApprovalItemReject = async (id, payload) => {
    try {
      const response = await axios.delete(
        `${api_url}/baselocation-approval/rejectlocationapprovalitem/${id}`,
        payload
      );
      await fetchBaseLocationList();
      return response.data.success;
      
    } catch (error) {
      console.error("There was an error updating the Request!", error);
      return false;
    }
  };

  

  const handleApprove = async (id, ops) => {
    if (!ops) {
      toast.error(
        `Failed to approve or reject.. Please contact administrator`,
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
          className: "custom-toast",
        }
      );
      return;
    }
    if (ops === "Approve") {
      let payload = {
        // StatusInd: "A",
        ActiveInd: 1
      };
      let result = await handleApprovalItemUpdate(id, payload);
      if (result === true) {
        toast.success(`Base Location Request Approved Successfully`, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
          className: "custom-toast",
        });
      }
      if (result === false) {
        toast.error(
          `Failed to approve... Please contact administrator`,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "light",
            className: "custom-toast",
          }
        );
      }
    }
    if (ops === "Reject") {
      let payload = {
        // StatusInd: "R"
        ActiveInd:0
      };
      let result = await handleApprovalItemReject(id);
      if (result === true) {
        toast.success(`Base Location Request Rejected`, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
          className: "custom-toast",
        });
      }
      if (result === false) {
        toast.error(
          `Failed to reject... Please contact administrator`,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "light",
            className: "custom-toast",
          }
        );
      }
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="container-fluid">
          <div className="button-wrapper">
            <div>
              <Menu link="/approval-dash" />
            </div>
          </div>
          {/* </div> */}
          <div className="table-view-container-wrapper">
            <div className="p-2 d-flex fw-bold shadow justify-content-between align-items-center mb-3">
              <span className="mx-auto">Base Location Approval List</span>
              {/* <div style={{ flex: "0 0 auto" }}>
                <select
                  style={{ width: `150px` }}
                  name="baselocationapprovallist"
                  className="form-input-select"
                  aria-label=".form-select-sm example"
                  onChange={(e) => setListType(e.target.value)}
                  value={listtype || ""}
                  required
                >
                  <option value="All">All Requests</option>
                  <option value="A">Approved</option>
                  <option selected value="P">
                    Pending
                  </option>
                  <option value="R">Rejected</option>
                </select>
              </div> */}
            </div>
            <table className="table table-hover table-responsive">
              <thead>
                <tr>
                  {labels.map((item, index) => (
                    <th
                      scope="col"
                      key={index}
                      className="table-header-font-size"
                    >
                      {item}{" "}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="table-body-font-size">
                {BaseLocationList.map((item, index) => {
                  const sno = index + 1;
                  return (
                    <tr
                      className="py-0"
                      key={index}
                      data-bs-toggle="modal"
                      data-bs-target="#baselocationmodal"
                      onClick={() => handleRowClick(item.LongLatID)}
                      style={{ cursor: "pointer" }}
                    >
                      <td className="py-0">{sno}</td>
                      <td className="py-0">{item.AtlasEmpID}</td>
                      <td className="py-0">{item.EmpName}</td>
                      {/*<td className="py-0">{item.BaseLong}</td> */}
                      <td className="py-0">{item.SubmittedDate}</td>
                      {/* <td className="py-0">{item.}</td> */}
                      {/* <td className="py-0">{item.StatusInd}</td> */}

                      <td className="py-0">
                        {/* {item.StatusInd.trim() === "P"
                          ? "Pending"
                          : item.StatusInd.trim() === "A"
                          ? "Approved"
                          : item.StatusInd.trim() === "R"
                          ? "Rejected"
                          : ""} */}
                          {item.ActiveInd === 0 ? "Pending": ""}
                      </td>
                      <td className="py-0">
                        <Link
                          to="#"
                        >
                          <FaEye onClick={() => handleRowClick(item.LongLatID)}/>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div
              className="modal fade"
              id="baselocationmodal"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex="-1"
              aria-labelledby="baselocationmodal"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="baselocationmodal">
                      Base Location Approval
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    {mapSrc && (
                      <iframe
                        width="100%"
                        height="300"
                        style={{ border: 0 }}
                        loading="lazy"
                        allowFullScreen=""
                        src={mapSrc} // Use dynamic mapSrc here
                      ></iframe>
                    )}
                  </div>
                  <div className="modal-footer">
                    {/* <Link to="/userpunch"> */}
                    {/* {BaseLocationItem && BaseLocationItem.StatusInd ? (
                      BaseLocationItem.StatusInd.trim() === "P" ? (
                        <> */}
                          <Button
                            type="submit"
                            variant="contained"
                            color="error"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              handleApprove(
                                BaseLocationItem.AtlasEmpID,
                                "Reject"
                              );
                            }}
                          >
                            Reject
                          </Button>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            data-bs-dismiss="modal"
                            className="ms-3"
                            onClick={() => {
                              handleApprove(
                                BaseLocationItem.LongLatID,
                                "Approve"
                              );
                            }}
                          >
                            Approve
                          </Button>
                        {/* </>
                      ) : null
                    ) : null} */}

                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BaseLocationApprovalList;
