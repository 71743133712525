import React, { useEffect, useState } from "react";
import { Menu } from "../../header/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import Loader from "../../Loader";

import { Button } from "@mui/material";
import "./ResumeCV.css";

import { nationalities } from "../../../data/nationalities";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ResumeCV = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const navigate = useNavigate();
  const [loading,setLoading] = useState(true);
  const [applicantDetails, setApplicantDetails] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [selectedDept, setSelectedDept] = useState("");

  const { CVID, mode } = location.state || {};

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);

  const getDepartments = async () => {
    try {
      const response = await axios.get(`${api_url}/emp/GetDepartments`);
      let result = response.data.results;
      setDepartments(result);
      setSelectedDept("");
      return result;
    } catch (error) {
      console.error("There was an error fetching the departments!", error);
    }
  };

  const getDesignations = async(e) => {
   await axios
      .get(`${api_url}/emp/GetDesignations/${e}`)
      .then((response) => {
        setDesignations(response.data);
      })
      .catch((err) => {
        console.error("Error fetching Desingations", err);
      });
  };

  const handleInputChanges = (event, field) => {
    let temp = { ...applicantDetails };
    temp[field] = event.target.value;
    setApplicantDetails(temp);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      alert("Please select a valid PDF file.");
    }
  };

  const handleDepartmentChange = (e) => {
    let value = e.target.value;
    if (value === "") {
      setSelectedDept(value);
      setDesignations([]);
    } else {
      const deptId = departments.find(
        (dept) => dept.DepartmentName === e.target.value
      ).DeptID;
      setSelectedDept(value);
      getDesignations(deptId);
    }
  };

  useEffect(() => {
    if (mode === "edit") {
      getApplicantDetails(CVID);
    } else if (mode === "add") {
      getDepartments();
      setLoading(false);
    }
  }, []);

  
  const InsertApplicantDetails = async () => {
    // let temp = { ...applicantDetails };
    // let payload = {
    //   FirstName: applicantDetails.FirstName,
    //   LastName: applicantDetails.LastName,
    //   Department: applicantDetails.Department || null,
    //   Designation: applicantDetails.Designation || null,
    //   ReportingManager: applicantDetails.ReportingManager || null
    // }
    let payload = {...applicantDetails}
    // console.log("ApplicantDetails",temp)
    await axios
      .post(
        `${api_url}/recruitment/createapplicantdetail`,
        payload, 
        {
          headers: {
            "Content-Type": "application/json", 
          },
        }
      )
      .then((response) => {
        console.log("Applicant details created successfully", response);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const getLatestApplicantID = async () => {
    try {
      const response = await axios.get(`${api_url}/recruitment/getlatestapplicantid`);
      const cvid = response.data[0]?.CVID; // Use optional chaining
      if (cvid) {
        return cvid; // Return CVID to use in the next step
      } else {
        throw new Error("CVID not found in response");
      }
    } catch (error) {
      console.error("Error fetching CV ID:", error);
      throw error; // Rethrow the error to be handled by the caller
    }
  };

  const UploadApplicantCV = async (file, cvid) => {
    try {
      const formData = new FormData();
      formData.append("CVID", cvid);
      formData.append("Resume", file);
      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }
      await axios.post(`${api_url}/recruitment/uploadcv/${cvid}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.error("Error uploading CV", error);
    }
  };

  
  const handleSubmit = (eve) => {
    eve.preventDefault();
    mode === "add" ? addApplicant() : EditApplicant(CVID);
  };

  const addApplicant = async() => {
    if(!selectedFile){
      toast.error("Please upload a CV.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });
    }
    else{
      if(!applicantDetails.FirstName || !applicantDetails.LastName){
        toast.error("First Name & Last Name required", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
        });
        return;
      }
      else{
        await InsertApplicantDetails();
        const latestApplicantID = await getLatestApplicantID();
        await UploadApplicantCV(selectedFile, latestApplicantID);
        toast.success("Applicant Details Added Successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
        });
     navigate("/cvdb");
      }
      
  }
  };
  const EditApplicant = async(id) => {
    await updateApplicantDetails(id);
    if(selectedFile){
      await UploadApplicantCV(selectedFile,id)
      toast.success("Applicant Details Updated Successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };

  const getApplicantDetails = async (id) => {
    try {
      const res = await axios.get(`${api_url}/recruitment/getapplicantdetails/${id}`);
      let applicant = res.data[0];
      let depts = await getDepartments();
      let dept_id = depts.find(
        (dept) => dept.DepartmentName === applicant.Department
      ).DeptID;
      getDesignations(dept_id);
      setSelectedDept(applicant.Department);
      setApplicantDetails(applicant);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const updateApplicantDetails = async(eid) => {
    await axios
      .put(`${api_url}/recruitment/updateapplicantdetails/${eid}`, applicantDetails)
      .then((response) => {
        if(response){
          navigate("/cvdb")
        }
        
      })
      .catch((error) => {
        console.error("There was an error updating!", error);
      });
   ;
  };

  const handleDelete = async (cvid) => {
    try{
      await axios
      .delete(`${api_url}/recruitment/deleterecord/${cvid}`)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    navigate("/cvdb");
    }
    catch{

    }
   
    toast.error("Applicant Details Deleted!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      theme: "light",
    });
  };


  const RedContainedButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(theme.palette.error.main), // Sets text color to contrast with red
    backgroundColor: theme.palette.error.main, // Sets background color to red
    "&:hover": {
      backgroundColor: theme.palette.error.dark, // Sets background color to darker red on hover
    },
  }));

  
  return (
    <>
    {loading?<Loader/> :<>
      {/* <div className=" header-nav-bar"> */}
      <div className="d-flex justify-content-between">
        <Menu link="/cvdb" />
      </div>
      <div className="emp-form d-block">
        {/* Form Starts */}
        <form
          className=""
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="emp-form-save-button">
            {mode === "edit" ? (
              <RedContainedButton
                variant="contained"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  handleDelete(CVID);
                }}
                className="m-2"
              >
                Delete
              </RedContainedButton>
            ) : (
              ""
            )}
            <Button type="submit" variant="contained" color="primary">
              Save Details
            </Button>
          </div>
          <div className="container-fluid">
            <div className="container p-3 form-wrapper border border-secondary">
              <div className="row sub-wrapper ">
                <h5 className="sub-wrapper-heading">Applicant Details</h5>
                <div className="col-6">
                  <label className="form-label" htmlFor="applicantfname">
                    Applicant's First Name<span className="required"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-input-text"
                    id="applicantfname"
                    placeholder=""
                    value={applicantDetails.FirstName || ""}
                    maxLength="150"
                    onChange={(e) => {
                      handleInputChanges(e, "FirstName");
                    }}
                    required
                  />
                   <label className="form-label" htmlFor="applicantlname">
                    Applicant's Last Name<span className="required"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-input-text"
                    id="applicantlname"
                    placeholder=""
                    value={applicantDetails.LastName || ""}
                    maxLength="150"
                    onChange={(e) => {
                      handleInputChanges(e, "LastName");
                    }}
                    required
                  />

                  <label className="form-label" htmlFor="department">
                    Department<span className="required"> *</span>
                  </label>
                  <select
                    onChange={(e) => {
                      handleInputChanges(e, "Department");
                      handleDepartmentChange(e);
                    }}
                    value={applicantDetails.Department || ""}
                    name="department"
                    className="form-input-select"
                    id="department"
                    aria-label=".form-select-sm example"
                    required
                  >
                    <option value="">Select Department</option>
                    {departments.map((dept) => (
                      <option key={dept.DeptID} value={dept.DepartmentName}>
                        {dept.DepartmentName}
                      </option>
                    ))}
                  </select>

                  <label className="form-label" htmlFor="designation">
                    Designation<span className="required"> *</span>
                  </label>
                  <select
                    className="form-input-select"
                    onChange={(e) => {
                      handleInputChanges(e, "Designation");
                    }}
                    id="designation"
                    name="designation"
                    value={applicantDetails.Designation || ""}
                    disabled={!selectedDept}
                    aria-label=".form-select-sm example"
                    required
                  >
                    <option value="">Select Designation</option>
                    {designations.map((item) => (
                      <option key={item.DesigID} value={item.DesignationName}>
                        {item.DesignationName}{" "}
                      </option>
                    ))}
                  </select>

                  <label className="form-label" htmlFor="nationality">
                    Nationality<span className="required"> *</span>
                  </label>
                  <select
                    name="nationality"
                    className="form-input-select"
                    aria-label=".form-select-sm example"
                    onChange={(e) => {
                      handleInputChanges(e, "Nationality");
                    }}
                    value={applicantDetails.Nationality || ""}
                    required
                  >
                   <option value="">Select Nationality</option>
                        {
                          nationalities.map((item,index)=>(
                            <option key={index} value={item}>{item}</option>
                          ))
                        }
                  </select>

                  <label className="form-label" htmlFor="email">
                    Email ID <span className="required">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-input-email"
                    id="email"
                    aria-describedby="emailHelp"
                    value={applicantDetails.EmailID || ""}
                    maxLength="100"
                    onChange={(e) => {
                      handleInputChanges(e, "EmailID");
                    }}
                    required
                  />

                  <label className="form-label" htmlFor="contactnumber">
                    Contact Number
                  </label>
                  <input
                    type="text"
                    className="form-input-text"
                    id="contactnumber"
                    placeholder=""
                    value={applicantDetails.ContactNumber || ""}
                    maxLength="20"
                    onChange={(e) => {
                      handleInputChanges(e, "ContactNumber");
                    }}
                  />

                  <label className="form-label" htmlFor="qualification">
                    Qualification
                  </label>
                  <input
                    type="text"
                    className="form-input-text"
                    id="qualification"
                    placeholder=""
                    value={applicantDetails.Qualification || ""}
                    maxLength="100"
                    onChange={(e) => {
                      handleInputChanges(e, "Qualification");
                    }}
                  />

                  <label className="form-label" htmlFor="yearsofexperience">
                    Years of Experience
                  </label>
                  <input
                    type="number"
                    className="form-input-number"
                    id="yearsofexperience"
                    placeholder=""
                    min={0}
                    value={applicantDetails.YearsofExperience || ""}
                    onChange={(e) => {
                      handleInputChanges(e, "YearsofExperience");
                    }}
                    onKeyDown={handleKeyDown}
                  />

                  <label className="form-label" htmlFor="referredby">
                    Referred By
                  </label>
                  <input
                    type="text"
                    className="form-input-text"
                    id="referredby"
                    placeholder=""
                    onChange={(e) => {
                      handleInputChanges(e, "ReferredBy");
                    }}
                    value={applicantDetails.ReferredBy || ""}
                    maxLength="50"
                  />

                  <label className="form-label" htmlFor="remarks">
                    Remarks
                  </label>
                  <textarea
                    className="form-input-textarea"
                    id="remarks"
                    rows="3"
                    columns="3"
                    onChange={(e) => {
                      handleInputChanges(e, "Remarks");
                    }}
                    value={applicantDetails.Remarks || ""}
                  ></textarea>

                  <div className="m-2">
                    <input
                      type="file"
                      id="resume"
                      name="resume"
                      accept="application/pdf"
                      onChange={(e) => {
                        handleFileChange(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>}
    </>
   
  );
};

export default ResumeCV;
