import React, { useState, useEffect } from "react";
import "./ScreeningGridList.css";
import axios from "axios";
import Loader from "../../../Loader";
import { Link} from "react-router-dom";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

const ScreeningGridList = ({ mode, recid }) => {
  const [loading, setLoading] = useState();
  const userData = JSON.parse(sessionStorage.getItem("CurrentUserData"));
  console.log("userdata", userData.EmpName);
  const [screeningItem, setScreeningItem] = useState({
    ScreeningMode: "",
    TechnicalProficiency: null,
    ProblemSolvingAbility: null,
    CommunicationSkills: null,
    ProjectManagement: null,
    TeamCollaboration: null,
    Adaptability: null,
    ClientRelationshipManagement: null,
    AnalyticalSkills: null,
    AttentionToDetail: null,
    RegulatoryKnowledge: null,
    InterviewedBy: "",
    InterviewedDate: "",
    Remarks: "",
    RecruitmentID: recid,
  });
  const [IsEditMode, setIsEditMode] = useState(false);
  const [screeningID, setScreeningID] = useState(0);

  const [screeningGridList, setScreeningGridList] = useState([]);

  const api_url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    getScreeningList();
    if (IsEditMode === true) {
      getScreeningItem(screeningID);
    }
  }, []);

  const getLatestScreeningID = async () => {
    try {
      const res = await axios.get(
        `${api_url}/recruitment/screening/latest-screening-id`
      );
      const ScreeningID = res.data.ScreeningID;
      return ScreeningID;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const getScreeningList = async () => {
    await axios
      .get(
        `${api_url}/recruitment/screening/screening-master-list/${screeningItem.RecruitmentID}`
      )
      .then((res) => {
        const response = res.data.list;
        setScreeningGridList(response);
      });
  };

  const handleInputChange = (e, field) => {
    let temp = { ...screeningItem };
    temp[field] = e.target.value;
    setScreeningItem(temp);
  };

  const resetScreeningItem = async () => {
    setScreeningItem({
      ScreeningMode: "",
      TechnicalProficiency: "",
      ProblemSolvingAbility: "",
      CommunicationSkills: "",
      ProjectManagement: "",
      TeamCollaboration: "",
      Adaptability: "",
      ClientRelationshipManagement: "",
      AnalyticalSkills: "",
      AttentionToDetail: "",
      RegulatoryKnowledge: "",
      InterviewedBy: "",
      InterviewedDate: "",
      Remarks: "",
      RecruitmentID: 16,
    });
  };

  const handleSave = async () => {
    const payload = {
      RecruitmentID: screeningItem.RecruitmentID || null,
      ScreeningMode: screeningItem.ScreeningMode || null,
      InterviewedBy: screeningItem.InterviewedBy || null,
      InterviewedDate: screeningItem.InterviewedDate || null,
      Remarks: screeningItem.Remarks || null,
    };

    try {
      const res = await axios.post(
        `${api_url}/recruitment/screening/create-screening-details`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status === 200) {
        const id = await getLatestScreeningID();
        await updateScreeningItem(id);
      }
      await resetScreeningItem();
      await getScreeningList();
    } catch (err) {
      console.log(err);
    }
  };

  const getScreeningItem = async (id) => {
    setLoading(true);
    await axios
      .get(`${api_url}/recruitment/screening/edit-screening-details/${id}`)
      .then((res) => {
        let value = res.data.data;
        const { CreatedDate, ...temp } = value;
        console.log("api response", temp);
        // let temp = {...payrollEarningitem}                             //to get payrollid and PR month and pryear
        // setScreeningItem((prev) => ({
        //   ...prev,
        //   ScreeningMode: value.EmpName,
        //   SkillRatings: value.EmpFullName,
        //   EarningAmount: value.EarningAmount,
        //   EarningType: value.EarningType,
        //   Remarks: value.Remarks,
        // }));
        setScreeningItem(temp);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  const updateScreeningItem = async (id) => {
    await axios
      .put(
        `${api_url}/recruitment/screening/update-screening-details/${id}`,
        screeningItem
      )
      .then((res) => {
        getScreeningList();
        resetScreeningItem();
        setIsEditMode(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // setPayrollEarningID(0);

    // resetpayrollEarningItem();
  };

  const handleSubmit = (id) => {
    IsEditMode ? updateScreeningItem(id) : handleSave();
  };

  console.log("screening item", screeningItem);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="p-1 d-flex justify-content-end">
            <Button
              type="button"
              variant="contained"
              color="primary"
              endIcon={<AddIcon />}
              data-bs-toggle="modal"
              data-bs-target="#screeninggridmodal"
            >
              New Record
            </Button>
          </div>
          {/* <div className="my-1 mx-5 p-1 d-block"> */}
            <div
              className="modal fade"
              id="screeninggridmodal"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex="-1"
              aria-labelledby="screeningmodal"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="screeninggridmodal">
                      Candidate Screening
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>

                  <div className="modal-body">
                    <div className="container-fluid">
                      <div className="row mb-2">
                        <div className="col-2">
                          <label
                            htmlFor="recruitment-interviewed-by"
                            className="form-label"
                          >
                            Interviewed By
                          </label>
                        </div>
                        <div className="col-4">
                          <select
                            className="form-select"
                            id="recruitment-interviewed-by"
                            onChange={(e) => {
                              handleInputChange(e, "InterviewedBy");
                            }}
                            value={screeningItem.InterviewedBy || ""}
                          >
                            <option value="">Choose...</option>
                            {/* <option value="HR">HR</option> */}
                            <option
                              value={`${userData.Designation} - ${userData.EmpName}`}
                            >
                              {`${userData.Designation} - ${userData.EmpName}`}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-2">
                          <label
                            htmlFor="interviewed-date"
                            className="form-label"
                          >
                            Interviewed Date
                          </label>
                        </div>
                        <div className="col-4">
                          <input
                            id="interviewed-date"
                            className="form-input-text"
                            type="date"
                            value={screeningItem.InterviewedDate || ""}
                            onChange={(e) => {
                              handleInputChange(e, "InterviewedDate");
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-2">
                          <label
                            htmlFor="screening-mode"
                            className="form-label"
                          >
                            Mode of Interview
                          </label>
                        </div>
                        <div className="col-4">
                          <select
                            className="form-select"
                            id="screening-mode"
                            onChange={(e) => {
                              handleInputChange(e, "ScreeningMode");
                            }}
                            value={screeningItem.ScreeningMode || ""}
                          >
                            <option value="">Choose...</option>
                            <option value="Telephonic Interview">
                              Telephonic Interview
                            </option>
                            <option value="Online Meeting">
                              Online Meeting
                            </option>
                            <option value="In-Person">In-Person</option>
                          </select>
                        </div>
                      </div>
                      <h5 className="mt-3">Skill Sets:</h5>
                      <div className="row mb-1">
                        <div className="col-4">
                          <label
                            className="form-label"
                            htmlFor="technical-profiency"
                          >
                            Technical Proficiency
                          </label>
                        </div>
                        <div className="col-5">
                          <input
                            type="number"
                            className="form-control w-100"
                            name="technical-profiency"
                            placeholder="Please give the rating... 1 - 5"
                            onChange={(e) => {
                              handleInputChange(e, "TechnicalProficiency");
                            }}
                            value={screeningItem.TechnicalProficiency}
                            id="technical-profiency"
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-4">
                          <label
                            className="form-label"
                            htmlFor="problem-solving-ability"
                          >
                            Problem-Solving Ability
                          </label>
                        </div>
                        <div className="col-5">
                          <input
                            type="number"
                            className="form-control w-100"
                            name="problem-solving-ability"
                            placeholder="Please give the rating... 1 - 5"
                            onChange={(e) => {
                              handleInputChange(e, "ProblemSolvingAbility");
                            }}
                            value={screeningItem.ProblemSolvingAbility}
                            id="problem-solving-ability"
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-4">
                          <label
                            className="form-label"
                            htmlFor="communication-skills"
                          >
                            Communication Skills
                          </label>
                        </div>
                        <div className="col-5">
                          <input
                            type="number"
                            className="form-control w-100"
                            name="communication-skills"
                            placeholder="Please give the rating... 1 - 5"
                            onChange={(e) => {
                              handleInputChange(e, "CommunicationSkills");
                            }}
                            value={screeningItem.CommunicationSkills}
                            id="communication-skills"
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-4">
                          <label
                            className="form-label"
                            htmlFor="project-management"
                          >
                            Project Management
                          </label>
                        </div>
                        <div className="col-5">
                          <input
                            type="number"
                            className="form-control w-100"
                            name="project-management"
                            placeholder="Please give the rating... 1 - 5"
                            onChange={(e) => {
                              handleInputChange(e, "ProjectManagement");
                            }}
                            value={screeningItem.ProjectManagement}
                            id="project-management"
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-4">
                          <label
                            className="form-label"
                            htmlFor="team-collaboration"
                          >
                            Team Collaboration
                          </label>
                        </div>
                        <div className="col-5">
                          <input
                            type="number"
                            className="form-control w-100"
                            name="team-collaboration"
                            placeholder="Please give the rating... 1 - 5"
                            onChange={(e) => {
                              handleInputChange(e, "TeamCollaboration");
                            }}
                            value={screeningItem.TeamCollaboration}
                            id="team-collaboration"
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-4">
                          <label className="form-label" htmlFor="adaptability">
                            Adaptability
                          </label>
                        </div>
                        <div className="col-5">
                          <input
                            type="number"
                            className="form-control w-100"
                            name="adaptability"
                            placeholder="Please give the rating... 1 - 5"
                            onChange={(e) => {
                              handleInputChange(e, "Adaptability");
                            }}
                            value={screeningItem.Adaptability}
                            id="adaptability"
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-4">
                          <label
                            className="form-label"
                            htmlFor="client-relationship-management"
                          >
                            Client Relationship Management
                          </label>
                        </div>
                        <div className="col-5">
                          <input
                            type="number"
                            className="form-control w-100"
                            name="client-relationship-management"
                            placeholder="Please give the rating... 1 - 5"
                            onChange={(e) => {
                              handleInputChange(
                                e,
                                "ClientRelationshipManagement"
                              );
                            }}
                            value={screeningItem.ClientRelationshipManagement}
                            id="client-relationship-management"
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-4">
                          <label
                            className="form-label"
                            htmlFor="analytical-skills"
                          >
                            Analytical Skills
                          </label>
                        </div>
                        <div className="col-5">
                          <input
                            type="number"
                            className="form-control w-100"
                            name="analytical-skills"
                            placeholder="Please give the rating... 1 - 5"
                            onChange={(e) => {
                              handleInputChange(e, "AnalyticalSkills");
                            }}
                            value={screeningItem.AnalyticalSkills}
                            id="analytical-skills"
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-4">
                          <label
                            className="form-label"
                            htmlFor="attention-to-detail"
                          >
                            Attention to Detail
                          </label>
                        </div>
                        <div className="col-5">
                          <input
                            type="number"
                            className="form-control w-100"
                            name="attention-to-detail"
                            placeholder="Please give the rating... 1 - 5"
                            onChange={(e) => {
                              handleInputChange(e, "AttentionToDetail");
                            }}
                            value={screeningItem.AttentionToDetail}
                            id="attention-to-detail"
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-4">
                          <label
                            className="form-label"
                            htmlFor="regulatory-knowledge"
                          >
                            Regulatory Knowledge
                          </label>
                        </div>
                        <div className="col-5">
                          <input
                            type="number"
                            className="form-control w-100"
                            name="regulatory-knowledge"
                            placeholder="Please give the rating... 1 - 5"
                            onChange={(e) => {
                              handleInputChange(e, "RegulatoryKnowledge");
                            }}
                            value={screeningItem.RegulatoryKnowledge}
                            id="regulatory-knowledge"
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-4">
                          <label
                            htmlFor="earn-emp-remarks"
                            className="form-label"
                          >
                            Remarks
                          </label>
                        </div>
                        <div className="col-8">
                          <textarea
                            className="form-input-textarea"
                            id="earn-emp-remarks"
                            rows="3"
                            columns="6"
                            onChange={(e) => {
                              handleInputChange(e, "Remarks");
                            }}
                            value={screeningItem.Remarks}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <Link to="#">
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          handleSubmit(screeningItem.ScreeningID);
                        }}
                      >
                        {IsEditMode ? "Update" : "Add"}
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-view-container-wrapper">
              <div className="p-2 d-flex fw-bold shadow justify-content-center blockquote">
                Screening List
              </div>
              <div>
                <table className="table table-hover table-responsive">
                  <thead>
                    <tr>
                      <th className="table-header-font-size">S.No</th>
                      <th className="table-header-font-size">Screening Type</th>
                      <th className="table-header-font-size">Interviewed By</th>
                      <th className="table-header-font-size">Rating</th>
                      <th className="table-header-font-size">Remarks</th>
                      <th className="table-header-font-size"></th>
                    </tr>
                  </thead>

                  <tbody className="table-body-font-size">
                    {screeningGridList.map((item, index) => (
                      <tr
                        className="py-0"
                        scope="row"
                        key={item.CVID}
                        data-bs-toggle="modal"
                        data-bs-target="#screeninggridmodal"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          getScreeningItem(item.ScreeningID);
                          setIsEditMode(true);
                        }}
                      >
                        <td className="py-0">{index + 1}</td>
                        <td className="py-0">{item.ScreeningMode}</td>
                        <td className="py-0">{item.InterviewedBy}</td>
                        <td className="py-0">{item.Designation}</td>
                        <td className="py-0">{item.Remarks}</td>
                        {/* <td className="py-0">{item.CurrentRevision}</td> */}
                        <td
                          className="py-0"
                          data-bs-toggle="modal"
                          data-bs-target="#screeninggridmodal"
                          onClick={() => {
                            getScreeningItem(item.ScreeningID);
                            setIsEditMode(true);
                          }}
                        >
                          {/* <Link
                          to="/hr/recruitment-plan-form"
                          state={{
                            mode: "edit",
                            recid: item.RequestID,
                          }}
                        > */}
                          <EditIcon className="abc" />

                          {/* </Link> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          {/* </div> */}
        </div>
      )}
    </>
  );
};

export default ScreeningGridList;
