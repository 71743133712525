import React, { useState, useEffect } from "react";
import { Menu } from "../../header/Header";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EditIcon from "@mui/icons-material/Edit";

import SearchIcon from "@mui/icons-material/Search";
import "./HRDocuments.css";
import Loader from "../../Loader";
import { FaEye } from "react-icons/fa6";

const HRDocuments = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [documentList, setDocumentList] = useState([]);
  const [searchText,setSearchText] = useState("");
  const [loading,setLoading] = useState(true);


  const [currentPage, setCurrentPage] = useState(1);

  const nextPage = () => {
      if (currentPage < npage) {
        setCurrentPage(currentPage + 1);
      }
    };
    const prevPage = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };
    const changeCpage = (id) => {
      setCurrentPage(id);
    };
   
  
const recordsPerPage = 25;
const lastIndex = currentPage * recordsPerPage;
const firstIndex = lastIndex - recordsPerPage;
const records = documentList.slice(firstIndex, lastIndex);
const npage = Math.ceil(documentList.length / recordsPerPage);
const numbers = [...Array(npage + 1).keys()].slice(1);

  useEffect(() => {
    if (!token) {
      navigate("/");
      }  
      const GetDocumentsList = () => {
        setLoading(true);
        try{
            axios
            .get(`${api_url}/hr/getdocumentlist`)
            .then((res) => {
                //  console.log("res",res);
                setDocumentList(res.data);
              })
        }
        catch(err){
            console.log(err);
        }
        finally{
            setLoading(false);
        }
      };  
      GetDocumentsList();

  }, []);

  

  const handleSearch = (e)=>{
    let value = e.target.value;
    setSearchText(value);
  }

  const SearchRecords = async()=>{
    const payload = {
      searchText: searchText
    }
    await axios.post(`${api_url}/hr/searchrecords`,payload)
    .then((res)=>{
      setDocumentList(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const handleView = async (Docid) => {
    const response = await axios.get(`https://atlas-om.app:30000/api/hr/viewdocument/${Docid}`)
    console.log(response);
    const url = response.data;
    console.log(url);
    window.open(url, '_blank');
  };
  return (
    <>
    {loading ? (
      <Loader />
    ) : (
      <div className="my-1 mx-5 p-1">
        <div className="d-block">
          <div className="d-inline-block align-middle">
            <Menu link="/hrdash" />
          </div>
          <div className="d-inline-block p-1 float-xxl-end float-xl-end float-md-end float-sm-end">
            <Link to="/hrdocform" state={{ mode: "add" }}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                endIcon={<ArrowForwardIcon />}
              >
                Add Document
              </Button>
            </Link>
          </div>
        </div>
        <div className="table-view-container-wrapper">
          <div className="p-2 mt-2 mb-4 d-flex shadow justify-content-between align-items-center">
            <div className="header-div">HR Document List</div>
            <div className="search-box-div">
              <input
                type="text"
                className="form-control search-box-input"
                placeholder="Search docs"
                onChange={(e) => handleSearch(e)}
              />
              <button onClick={SearchRecords}>
                <SearchIcon className="search-box-icon" />
              </button>
            </div>
          </div>
          <div>
            <table className="table table-hover table-responsive">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Document Name</th>
                  <th>Purchase Date</th>
                  <th>Expiry Date</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="table-body-font-size">
                {records.map((item, index) => (
                  <tr className="py-0" scope="row" key={item.DocumentID}>
                    {/* <td className="py-0">{index + 1}</td> */}
                    <td className="py-0">{item.DocumentID}</td>
                    <td className="py-0">{item.DocumentName}</td>
                    <td className="py-0">{item.PurchaseDate}</td>
                    <td className="py-0">{item.ExpiryDate}</td>
                    <td className="py-0">
                        <FaEye className="view-document" onClick={() => {handleView(item.DocumentID)}} />
                    </td>
                    <td className="py-0">
                      <Link
                        to="/hrdocform"
                        state={{
                          mode: "edit",
                          DocumentID: item.DocumentID,
                        }}
                      >
                        <EditIcon />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <nav
          aria-label="Page navigation example"
          className="table-responsive mb-2"
        >
          <ul className="pagination justify-content-center">
            <li className="page-item">
              <a
                className={`${currentPage === 1 ? "greyed-button" : "page-link"}`}
                disabled={currentPage === 1}
                onClick={prevPage}
                href="#"
              >
                Previous
              </a>
            </li>
            {numbers.map((n, i) => (
              <li
                className={`page-item${currentPage === n ? " active" : ""}`}
                key={i}
              >
                <a className="page-link" href="#" onClick={() => changeCpage(n)}>
                  {n}
                </a>
              </li>
            ))}
            <li className="page-item">
              <a
                className={`${currentPage === npage ? "greyed-button" : "page-link"}`}
                href="#"
                disabled={currentPage === npage}
                onClick={nextPage}
              >
                Next
              </a>
            </li>
          </ul>
        </nav>
      </div>
    )}
  </>

  );
};

export default HRDocuments;
