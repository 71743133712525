import React from "react";
import "./RequestDashboard.css";
import { Link, useNavigate } from "react-router-dom";

import { SiAmazonsimpleemailservice } from "react-icons/si";
import { FaPlaneCircleExclamation,FaFileCircleCheck,FaComputer } from "react-icons/fa6";
import { GrUserNew } from "react-icons/gr";
import { RiFolderUserFill,RiFileUserLine  } from "react-icons/ri";
import { GiWeightLiftingUp,GiReceiveMoney,GiFamilyTree,GiPayMoney } from "react-icons/gi";




const RequestDashboard = () => {
  
  const navigate = useNavigate();
  const token = localStorage.getItem("token")
  if (!token) {
    navigate("/");
  }
  const userData = JSON.parse(sessionStorage.getItem("CurrentUserData"));


  return (
    <>
      <div>
        <div className="container-fluid page-title">
          <div className="container my-3 px-2">
            <h4 className="mb-2">Requests</h4>
            <div className="row g-4">
              <div className="col-12 col-md-6 col-lg-2" >
                <Link to="/requests/leave-request-list" state={{ list:"request-list" }} className="text-decoration-none">
                  <div className="border dashboard-card-wrapper employees">
                    <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                      Leave Requests
                      <RiFolderUserFill className="dashboard-card-icon" />
                    </div>
                  </div>
                </Link>
              </div>
              {/* {
                (userData.IsReportingManager === 1 && userData.AtlasEmpID != "AEC002") ?
                 <>
                  <div className="col-12 col-md-6 col-lg-2" >
                <Link to="/requests/leave-approvals-list"  className="text-decoration-none">
                  <div className="p-2 border  dashboard-card-wrapper request-approvals">
                    <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                      Request Approvals
                      <FaFileCircleCheck className="dashboard-card-icon" />
                    </div>
                  </div>
                </Link>
              </div>
                </>
                :""
              }
              */}
              <div className="col-12 col-md-6 col-lg-2" >
                <Link to="/requests/it-complaint-list" className="text-decoration-none">
                  <div className="p-2 border  dashboard-card-wrapper payroll">
                    <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                      IT Complaint Requests
                      <FaComputer className="dashboard-card-icon" />
                    </div>
                  </div>
                </Link>
              </div>
              {/* <div className="col-12 col-md-6 col-lg-2" >
                <Link to="#" className="text-decoration-none">
                  <div className="border  dashboard-card-wrapper ffs">
                    <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                      Full & Final Settlement
                      <FaPlaneCircleExclamation className="dashboard-card-icon" />
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-12 col-md-6 col-lg-2" >
                <Link to="#" className="text-decoration-none">
                  <div className="border performance  dashboard-card-wrapper">
                    <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                      Performance
                      <GiWeightLiftingUp className="dashboard-card-icon" />
                    </div>
                  </div>
                </Link>
              </div> */}
            </div>
          </div>
          {/* <div className="container my-3 px-2">
            <h4 className="mb-2">Talent Management</h4>
            <div className="row g-3">
              <div className="col-12 col-md-6 col-lg-2" >
                <Link to="#" className="text-decoration-none">
                  <div className="p-2 border  dashboard-card-wrapper recruitment-plan">
                    <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                      Recruitment Plan
                      <FaUserPlus className="dashboard-card-icon" />
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-2" >
                <Link to="#" className="text-decoration-none">
                  <div className="p-2 border dashboard-card-wrapper manpower-request">
                    <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                      Manpower Request
                      <GrUserNew className="dashboard-card-icon" />
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-2" >
                <Link to="#" className="text-decoration-none">
                  <div className="p-2 border  dashboard-card-wrapper recruitment-process">
                    <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                      Recruitment Process
                      <FaUserPlus className="dashboard-card-icon" />
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-2" >
                <Link to="/cvdb" className="text-decoration-none">
                  <div className="p-2 border  dashboard-card-wrapper applicant-db">
                    <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                      Applicant Database
                      <RiFileUserLine className="dashboard-card-icon" />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="container my-3 px-2">
            <h4 className="mb-2">HR Utilities</h4>
            <div className="row g-3">
              <div className="col-12 col-md-6 col-lg-2" >
                <Link to="/circulars" className="text-decoration-none">
                  <div className="p-2 border  dashboard-card-wrapper circulars">
                    <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                      Circulars
                      <SiAmazonsimpleemailservice className="dashboard-card-icon" />
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-2" >
                <Link to="#" className="text-decoration-none">
                  <div className="p-2 border  dashboard-card-wrapper organization">
                    <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                      Organization
                      <GiFamilyTree className="dashboard-card-icon" />
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-2" >
                <Link to="#" className="text-decoration-none">
                  <div className="p-2 border  dashboard-card-wrapper pasi">
                    <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                      PASI
                      <GiPayMoney className="dashboard-card-icon" />
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-12 col-md-6 col-lg-2" >
                <Link to="/hrdocs" className="text-decoration-none">
                  <div className="p-2 border  dashboard-card-wrapper documents">
                    <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                      Documents
                      <FaFile className="dashboard-card-icon" />
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-2" >
                <Link to="/hrformsmgmt" className="text-decoration-none">
                  <div className="p-2 border  dashboard-card-wrapper applicant-db">
                    <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                      HR Forms
                      <RiFileUserLine className="dashboard-card-icon" />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="container my-3 px-2">
            <h4 className="mb-2">Reports</h4>
            <div className="row g-3">
              <div className="col-12 col-md-6 col-lg-2" >
                <Link to="#" className="text-decoration-none">
                  <div className="p-2 border  dashboard-card-wrapper leave-report">
                    <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                      Leave Report
                      <SiAmazonsimpleemailservice className="dashboard-card-icon" />
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-2" >
                <Link to="#" className="text-decoration-none">
                  <div className="p-2 border  dashboard-card-wrapper timesheet-report">
                    <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                      TimeSheet Report
                      <SiAmazonsimpleemailservice className="dashboard-card-icon" />
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-2" >
                <Link to="#" className="text-decoration-none">
                  <div className="border  dashboard-card-wrapper leave-planner">
                    <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                      Leave Planner
                      <FaPlaneCircleExclamation className="dashboard-card-icon" />
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-2">
                <Link to="#" className="text-decoration-none">
                  <div className="p-2 border  dashboard-card-wrapper gratuity">
                    <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                      Gratuity Reports
                      <TbPigMoney className="dashboard-card-icon" />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default RequestDashboard;
