import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';

import { Menu } from '../../../header/Header';
import RecruitmentMasterForm from './RecruitmentMasterForm';
import ScreeningGridList from './ScreeningGridList';
import RecruitmentDocuments from './RecruitmentDocuments';
import RecruitmentOfferLetter from './RecruitmentOfferLetter';
import RecruitmentOfferList from './RecruitmentOfferList';
import { useLocation } from 'react-router-dom';

import TestOffer from './TestOffer';

const steps = ['Candidate Information', 'Interview Process', 'Documents', 'Draft Offer'];

export default function RecruitmentMasterStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const location = useLocation();
  const { mode, recid } = location.state || {};

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return <RecruitmentMasterForm mode={mode} recid={recid} />;
      case 1:
        return <ScreeningGridList mode={mode} recid={recid} />;
      case 2:
        return <RecruitmentDocuments mode={mode} recid={recid}/>;
      case 3:
          // return <RecruitmentOfferList mode={mode} recid={recid}/>;
          return <TestOffer mode={mode} recid={recid}/>;
      default:
        return null;
    }
  };

  return (
    <>
      <Menu link="/hr/recruitment-master-list" />
      <Box sx={{ width: '100%', marginTop: "50px", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Stepper activeStep={activeStep} sx={{ width: '100%', maxWidth: '600px', justifyContent: 'center' }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <React.Fragment>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, width: '100%', maxWidth: '600px' }}>
            <Button
             variant="contained"
            //   color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleNext}
             variant="contained">
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
          {renderStepContent()}
        </React.Fragment>
      </Box>
    </>
  );
}
