import React, { useState, useEffect } from "react";
import "./Timesheet.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Menu } from "../../header/Header";
import { Button } from "@mui/material";
import Loader from "../../Loader";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Timesheet = () => {
  const [loading, setLoading] = useState(false);
  const [formDetails, setFormDetails] = useState("");
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [dateStrings, setDateStrings] = useState([]);
  const navigate = useNavigate();
  const api_url = process.env.REACT_APP_API_URL;

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);

  const handleInputChanges = (event, field) => {
    let temp = { ...formDetails };
    temp[field] = event.target.value;
    setFormDetails(temp);
  };

  const getFormDetails = async (id) => {
    try {
      const res = await axios.get(
        `${api_url}/timesheet/get-timesheet-details/${id}`
      );
      let plan = res.data.data[0];
      console.log("data from api", plan);
      setFormDetails(plan);
    } catch (err) {
      console.log(err);
    }
  };
  // "/timesheet/update-timesheet-details/:id

  const updateTimeSheetDetails = async (id) => {
    let payload = { ...formDetails };
    try {
      await axios.put(
        `${api_url}/timesheet/update-timesheet-details/${id}`,
        payload
      );
      return true;
    } catch (error) {
      console.error("There was an error updating the employee!", error);
      return false;
    }
    // finally {
    //   navigate("/empmgmt");
    // }
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      // if (mode === "edit") {
      await getFormDetails(1);
      // } else if (mode === "add") {
      // await getLatestFileNumber();
      // }
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleSubmit = async (eve) => {
    eve.preventDefault();
    // mode === "add" ? addPlan() : EditPlan(reqid);
    await updateTimeSheetDetails(1);
  };

  const handleDates = (startdate, enddate) => {
    if (!startdate || !enddate) {
      alert("Start date and End date should not be null");
      return;
    }

    const startDate = new Date(startdate);
    const endDate = new Date(enddate);

    let generatedDates = [];

    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const dayOfWeek = currentDate.getDay();
      const formattedDate = currentDate.toLocaleDateString("en-GB");
      const label = dayOfWeek === 5 || dayOfWeek === 6 ? "WH" : "NW";

      generatedDates.push(`${formattedDate}, ${label}`);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    setDateStrings(generatedDates);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <Menu link="/hrdash" />
          </div>
          <div className="emp-form d-block">
            {/* Form Starts */}
            <form
              className=""
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <div className="container-fluid">
                <div className="container p-3 form-wrapper border border-secondary">
                  <div className="row sub-wrapper mb-2 ">
                    <h5 className="sub-wrapper-heading">TimeSheet Form</h5>
                    <div className="col-12 col-lg-6 col-xl-6 col-xxl-6">
                      <label className="form-label" htmlFor="timesheetdate">
                        Date<span className="required"> *</span>
                      </label>
                      <input
                        type="date"
                        className="form-input-date"
                        id="timesheetdate"
                        placeholder=""
                        value={formDetails.Date || ""}
                        onChange={(e) => {
                          handleInputChanges(e, "Date");
                        }}
                        required
                      />

                      <label className="form-label" htmlFor="typeofday">
                        Type Of Day<span className="required"> *</span>
                      </label>
                      <select
                        onChange={(e) => {
                          handleInputChanges(e, "TypeOfDay");
                        }}
                        value={formDetails.TypeOfDay || ""}
                        name="typeofday"
                        className="form-input-select"
                        id="typeofday"
                        aria-label=".form-select-sm example"
                        required
                      >
                        <option value="">Select Type of Day</option>
                        <option value="NW">Normal Work Day</option>
                        <option value="WK">Weekend Holiday</option>
                        <option value="PH">Public Holiday</option>
                      </select>

                      <label className="form-label" htmlFor="allcutoffhours">
                        AllCutOffHours
                      </label>
                      <input
                        type="time"
                        className="form-input-date"
                        id="allcutoffhours"
                        placeholder=""
                        value={formDetails.AllCutOffHours || ""}
                        onChange={(e) => {
                          handleInputChanges(e, "AllCutOffHours");
                        }}
                      />

                      <label className="form-label" htmlFor="omanicutoffhrs">
                        OmaniCutOffHrs
                      </label>
                      <input
                        type="time"
                        className="form-input-date"
                        id="omanicutoffhrs"
                        placeholder=""
                        value={formDetails.OmaniCutOffHrs || ""}
                        onChange={(e) => {
                          handleInputChanges(e, "OmaniCutOffHrs");
                        }}
                      />

                      <label className="form-label" htmlFor="islamcutoffhrs">
                        IslamCutOffHrs
                      </label>
                      <input
                        type="time"
                        className="form-input-date"
                        id="islamcutoffhrs"
                        placeholder=""
                        value={formDetails.IslamCutOffHrs || ""}
                        onChange={(e) => {
                          handleInputChanges(e, "IslamCutOffHrs");
                        }}
                      />

                      <label className="form-label" htmlFor="expatcutoffhrs">
                        ExpatCutOffHrs
                      </label>
                      <input
                        type="time"
                        className="form-input-date"
                        id="expatcutoffhrs"
                        placeholder=""
                        value={formDetails.ExpatCutOffHrs || ""}
                        onChange={(e) => {
                          handleInputChanges(e, "ExpatCutOffHrs");
                        }}
                      />

                      <label className="form-label" htmlFor="remarks">
                        Remarks
                      </label>
                      <textarea
                        onChange={(e) => {
                          handleInputChanges(e, "Remarks");
                        }}
                        value={formDetails.Remarks || ""}
                        className="form-control"
                        id="remarks"
                        rows="3"
                        columns="6"
                        maxLength="1000"
                      ></textarea>
                      <label className="form-label" htmlFor="startdate">
                        Start Date
                      </label>
                      <input
                        type="date"
                        className="form-input-date"
                        id="startdate"
                        placeholder=""
                        // value={formDetails.ExpatCutOffHrs || ""}
                        onChange={(e) => {
                          setStartDate(e.target.value);
                        }}
                      />
                      <label className="form-label" htmlFor="enddate">
                        End Date
                      </label>
                      <input
                        type="date"
                        className="form-input-date"
                        id="enddate"
                        placeholder=""
                        // value={formDetails.ExpatCutOffHrs || ""}
                        onChange={(e) => {
                          setEndDate(e.target.value);
                        }}
                      />
                      <button
                        onClick={() => {
                          handleDates(startdate, enddate);
                        }}
                        className="btn btn-primary"
                      >
                        Generate
                      </button>
                    </div>
                    <div>
                      {dateStrings.map((date, index) => (
                        <p key={index}>{date}</p>
                      ))}
                    </div>

                    <div className="d-flex justify-content-end mt-2">
                      <Button type="submit" variant="contained" color="primary">
                        Save Details
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default Timesheet;
