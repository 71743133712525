// export const fetchCurrentDateTime = () => {
//   const now = new Date();
  
//   const utcTime = new Date(now.toUTCString());
  
//   const utcPlus4 = new Date(utcTime.getTime() + 4 * 60 * 60 * 1000);
  
//     const dateOptions = { 
//     day: '2-digit', 
//     month: '2-digit', 
//     year: 'numeric' 
//   };
  
//   const timeOptions = { 
//     hour: '2-digit', 
//     minute: '2-digit', 
//     second: '2-digit', 
//     hour12: false 
//   };
  
//   // Format the date and time using 'en-GB' locale
//   const formattedDate = utcPlus4.toLocaleString('en-GB', dateOptions);
//   const formattedTime = utcPlus4.toLocaleString('en-GB', timeOptions);
  
//   return { date: formattedDate, time: formattedTime };
// };


// export const fetchCurrentDateTime = () => {
//   const now = new Date();

//   // Get the current timezone offset in minutes from UTC (e.g., -240 for Oman which is UTC+4)
//   const timeZoneOffset = now.getTimezoneOffset(); 

//   // Oman is UTC +4, so the offset in minutes for Oman is -240.
//   // If the user's time zone is already UTC +4 (Oman), no adjustment is needed.
//   const isInOman = timeZoneOffset === -240;

//   let date, time;

//   if (isInOman) {
//     // If the user is in Oman, we can directly use the local time (without any offset)
//     const dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
//     const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };

//     date = now.toLocaleString('en-GB', dateOptions);
//     time = now.toLocaleString('en-GB', timeOptions);
//   } else {
//     // If the user is not in Oman, we first convert the time to UTC, then add 4 hours for Oman (UTC +4)
//     const utcTime = new Date(now.toUTCString()); // Convert current time to UTC
//     const omanTime = new Date(utcTime.getTime() + 4 * 60 * 60 * 1000); // Add 4 hours to UTC to get Oman time

//     const dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
//     const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };

//     date = omanTime.toLocaleString('en-GB', dateOptions);
//     time = omanTime.toLocaleString('en-GB', timeOptions);
//   }

//   // Format the date in yyyy-mm-dd format (for consistency)
//   // const [day, month, year] = date.split('/'); // dd/mm/yyyy
//   // const formattedDate = `${year}-${month}-${day}`; 

//   return { date: date, time:time };
// };

export const fetchCurrentDateTime = () => {
  const now = new Date();

  // Get the current time in Muscat timezone (Asia/Muscat)
  const muscatTime = now.toLocaleString('en-GB', {
    timeZone: 'Asia/Muscat', 
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, 
  });

  // The muscatTime will return both date and time in a string
  const [date, time] = muscatTime.split(', ');

  return { date, time };
};
