import React, { useState, useEffect } from "react";
import Loader from "../../../Loader";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { fetchCurrentDateTime } from "../../CommonFunctions";
import { FaEye } from "react-icons/fa6";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Button } from "@mui/material";
import { Menu } from "../../../header/Header";
import { toast } from "react-toastify";

const LeaveRequestForm2 = () => {
  const userData = JSON.parse(sessionStorage.getItem("CurrentUserData"));
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const api_url = process.env.REACT_APP_API_URL;
  const [requestDetails, setRequestDetails] = useState({});
  const [reqid, setReqid] = useState(location.state?.reqid || null);
  const [leaveType, setLeaveType] = useState(null);
  const [leaveAttachment, setLeaveAttachment] = useState(null);

  useEffect(() => {
    if (reqid === "new") {
      setRequestDetails({
        ...requestDetails,
        AtlasEmpID: userData?.AtlasEmpID || "",
        EmpName: userData?.EmpName || "",
        Department: userData?.Department || "",
        Designation: userData?.Designation || "",
        ReportingManager: userData?.ReportingManager || "",
        ReportingManagerName: userData?.ReportingManagerName || "",
        HRManagerName: userData?.HRManagerName || "",
      });
      setLoading(false);
      axios
      .get(`${api_url}/request/leave-requests/availableannualleave/${userData.AtlasEmpID}`)
      .then((res) => {
        console.log("response of getting the request", res.data.data);
        // setRequestDetails(res.data.data);
        // setLeaveAttachment(res.data.AttachmentURL)
        
      //   console.log("typeofurl",typeof(res.data.AttachmentURL))
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching request details:", error);
        setLoading(true);
      });
    } else {
      // Fetch data from API for existing request
      axios
        .get(`${api_url}/requests/getrequestdetails/${reqid}`)
        .then((res) => {
          console.log("response of getting the request", res.data.data);
          setRequestDetails(res.data.data);
          setLeaveAttachment(res.data.AttachmentURL)
        //   console.log("typeofurl",typeof(res.data.AttachmentURL))
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching request details:", error);
          setLoading(true);
        });
    }
  }, [reqid]);
  const link =
    requestDetails.AtlasEmpID === userData.AtlasEmpID
      ? "/requests/leave-request-list"
      : "/requests/leave-approvals-list";

  // const currentDateTime = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
  const temp = fetchCurrentDateTime(); 
   
  const { date, time } = temp;
  
  // Now format the date and time into MySQL's format 'YYYY-MM-DD HH:MM:SS'
  const [day, month, year] = date.split('/');  // '05/11/2024' => ['05', '11', '2024']
  const formattedDate = `${year}-${month}-${day}`;  // '2024-11-05'
  const currentDateTime = `${formattedDate} ${time}`;  // '2024-11-05 16:36:45'
  
  const handleInputChanges = (event, field) => {
    let type = event.target.type;
    let val = event.target.value;
    if (type === "date" && val === "") {
      val = null;
    }
    let request = { ...requestDetails };
    request[field] = val;
    setRequestDetails(request);
  };



  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === "application/pdf") {
        setLeaveAttachment(file);
      } else {
        e.target.value = null;
        alert("Invalid file type. Please select an pdf file.");
      }
    } else {
      alert("Please re-upload the file");
    }
  };

  const CalculateDateDifference = (startdate, enddate) => {
    if (startdate && enddate) {
      const startDate = new Date(startdate);
      const endDate = new Date(enddate);

      // Set the time to the start of the day for both dates
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);

      // Calculate the time difference
      const timeDifference = endDate - startDate;

      // Calculate the difference in days, adding 1 to account for the same day
      const daysDifference =
        1 + Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      // Return formatted output
      if (daysDifference <= 0) {
        return "1 day"; // If the dates are the same or invalid
      }

      return daysDifference === 1 ? `1 day` : `${daysDifference} days`;
    }
    return null;
  };

  const difference = CalculateDateDifference(
    requestDetails.LeaveStarts,
    requestDetails.LeaveEnds
  );

  const InsertRequestDetails = async (payload) => {
    try {
      const response = await axios.post(
        `${api_url}/requests/createleaverequest`,
        payload,
        {
          headers: {
            "Content-Type": "application/json", 
          },
        }
      );
      if (response.data.success === true) {
        toast.success("Request Saved Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
          className: "custom-toast",
        });
      }
      return response.data.success;
    } catch (error) {
      console.log("Error:", error);
      return false;
    }
  };

  const handleUploadAttachment = async (file, id) => {
    try {
      const formData = new FormData();
      formData.append("Attachment", file);
      await axios.post(`${api_url}/requests/leave-requests/uploadattachment/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.error("Error uploading CV", error);
    }
  };

  const getLatestRequestID = async () => {
    try {
      const response = await axios.get(
        `${api_url}/leaverequest/getlatestrequestid`
      );
      const requestid = response.data?.RequestID;
      if (requestid) {
        return requestid;
      } else {
        throw new Error("RequestID not found in response");
      }
    } catch (error) {
      console.error("Error fetching Request ID:", error);
      throw error; // Rethrow the error to be handled by the caller
    }
  };

  const handleupdaterequest = async (id, payload) => {
    try {
      const response = await axios.put(
        `${api_url}/request/updaterequestdetails/${id}`,
        payload
      );
      return response.data.success;
    } catch (error) {
      console.error("There was an error updating the Request!", error);
      return false;
    } finally {
      // navigate("/requests/leave-request-list");
    }
  };

  const handleView = async (url) => {
    try {
      // const response = await axios.post("https://atlas-om.app:30000/api/emp/viewdocument", { Docid, AtlasEmpID });
      // const url = response.data;
      window.open(url, "_blank");
    } catch (error) {
      toast.error( "Failed to retrieve document. Please contact administrator", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
        className:"custom-toast"
      });
    
    }
  };

  const handleSubmit = async(e, operation)=>{
    e.preventDefault();
    if (!requestDetails.LeaveType) {
        alert("Please select a Leave Type.");
        return;
      }
      if (!requestDetails.LeaveStarts) {
        alert("Please select a Leave Start date.");
        return;
      }
      if (!requestDetails.LeaveEnds) {
        alert("Please select a Leave End date.");
        return;
      }



        //operation save begins
    if (operation === "save") {
        //manager or HR holding the request by adding comments and saving
        if (requestDetails.AtlasEmpID != userData.AtlasEmpID) {
          let temp = {
            ManagerComments: requestDetails.ManagerComments,
            HRComments: requestDetails.HRComments,
          };
          await handleupdaterequest(reqid, temp);
          toast.success("Request Saved Successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "light",
            className: "custom-toast",
          });
          navigate("/requests/leave-approvals-list");
        } 
        //User editing same request and saving it.
        else if (reqid != "new" && requestDetails.StatusInd === "C") {
          let temp = {
            LeaveType: requestDetails.LeaveType,
            LeaveStarts: requestDetails.LeaveStarts,
            LeaveEnds: requestDetails.LeaveEnds,
          };
          await handleupdaterequest(reqid, temp);
          if(leaveAttachment){
            await handleUploadAttachment(leaveAttachment,reqid);
          }
          toast.success("Request Saved Successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "light",
            className: "custom-toast",
          });
        } 
        //Normal save or initial creation of request
        else {
          let temp = {
            ...requestDetails,
            StatusInd: "C", 
          };
          let success = await InsertRequestDetails(temp);
          if (success) {
            try {
              setLoading(true);
              const latestId = await getLatestRequestID();
              
              if(leaveAttachment){
                await handleUploadAttachment(leaveAttachment,latestId);
              }
              console.log("saving the request attachment url",leaveAttachment)
              setReqid(latestId);
              setLoading(false);
            } catch (error) {
              console.error("Failed to fetch latest request ID:", error);
              setLoading(true); 
            }
          }
        }
      }
      //Operation save is end

       //Submit Operation begins
    else if (operation === "submit") {
        //HR Submission
          if (userData.AtlasEmpID === "AEC002") {
            let payload = {
              ...requestDetails,
              StatusInd: "A",
              HRApproval: "N",
              ReportingManagerApproval: "N",
              SubmittedDate: currentDateTime,
            };
            if(requestDetails.LeaveType==="SL"){
                if(!leaveAttachment){
                    alert("Attachment is needed for sick leave");
                    return 
                }
            }
    
            //Checking whether it is a new request (Direct Submission for HR)
            if (reqid === "new") {
               
              await InsertRequestDetails(payload);
              const latestID = await getLatestRequestID();
              console.log("Latest id from hr direct submission")
              if(leaveAttachment){

                await handleUploadAttachment(leaveAttachment,latestID);
              }
              
            } 
            //Not direct submission. Saved previously submitting now for HR
            else {
              let payload = {
                StatusInd: "A",
                HRApproval: "N",
                ReportingManagerApproval: "N",
                SubmittedDate: currentDateTime,
              };
              await handleupdaterequest(reqid, payload);
              // if(typeof(leaveAttachment)==="string"){

              // }
              await handleUploadAttachment(leaveAttachment,reqid);
              // console.log("Attachmenturl state",leaveAttachment);
              //code for uploading file attachment for hr submission. We need to ensure the file is there before uploading it. Because it is a edit request in which the file is only have the link

            }
          }
          //Manager Submission
          else if (userData.IsReportingManager === 1 && userData.AtlasEmpID?.trim() != "AEC002") {
            if(requestDetails.LeaveType==="SL"){
                if(!leaveAttachment){
                    alert("Attachment is needed for sick leave");
                    return 
                }
            }
            //direct submission for manager
            if (reqid === "new") {
              let payload = {
                ...requestDetails,
                StatusInd: "P",
                HRApproval: "P",
                ReportingManagerApproval: "N",
                SubmittedDate: currentDateTime,
              };
              await InsertRequestDetails(payload);
              const latestID = await getLatestRequestID();
              if(leaveAttachment){
                await handleUploadAttachment(leaveAttachment,latestID);
              }
              
            } 
            //Not direct submission for manager
            else {
              let payload = {
                StatusInd: "P",
                HRApproval: "P",
                ReportingManagerApproval: "N",
                SubmittedDate: currentDateTime,
              };
              await handleupdaterequest(reqid, payload);
              if(leaveAttachment){
                await handleUploadAttachment(leaveAttachment,reqid);
              }
               //code for uploading file attachment for hr submission. We need to ensure the file is there before uploading it. Because it is a edit request in which the file is only have the link
            }
          }
    
          //Normal User
          else if (userData.IsReportingManager === 0) {
            if(requestDetails.LeaveType==="SL"){
                if(!leaveAttachment){
                    alert("Attachment is needed for sick leave");
                    return 
                }
            }
            //Checking reporting manager and hr manager same or not
            if (userData.ReportingManager?.trim() === userData.HRManager?.trim()) {
              if (reqid === "new") {
                let payload = {
                  ...requestDetails,
                  StatusInd: "P",
                  HRApproval: "P",
                  ReportingManagerApproval: "N",
                  SubmittedDate: currentDateTime,
                };
                await InsertRequestDetails(payload);
                const latestID = await getLatestRequestID();
                await handleUploadAttachment(leaveAttachment,latestID);
              } else {
                let payload = {
                  StatusInd: "P",
                  HRApproval: "P",
                  ReportingManagerApproval: "N",
                  SubmittedDate: currentDateTime,
                };
                await handleupdaterequest(reqid, payload);
                await handleUploadAttachment(leaveAttachment,reqid);
                //code for uploading file attachment for hr submission. We need to ensure the file is there before uploading it. Because it is a edit request in which the file is only have the link
              }
            } else {
              if (reqid === "new") {
                let payload = {
                  ...requestDetails,
                  StatusInd: "P",
                  HRApproval: null,
                  ReportingManagerApproval: "P",
                  SubmittedDate: currentDateTime,
                };
                await InsertRequestDetails(payload);
                const latestID = await getLatestRequestID();
                await handleUploadAttachment(leaveAttachment,latestID);
              } else {
                let payload = {
                  StatusInd: "P",
                  ReportingManagerApproval: "P",
                  SubmittedDate: currentDateTime,
                };
                await handleupdaterequest(reqid, payload);
                await handleUploadAttachment(leaveAttachment,reqid);
                //code for uploading file attachment for hr submission. We need to ensure the file is there before uploading it. Because it is a edit request in which the file is only have the link
              }
            }
          }
          toast.success("Request Submitted Successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "light",
            className: "custom-toast",
          });
          navigate("/requests/leave-request-list", {
            state: { list: "request-list" },
          });    
      }
  
      //submit operation ends

      //Approval Operation starts
      else if (operation === "approve") {
        //HR Approval
        // if (userData.Designation.trim() === "HR Manager") {
        if (userData.AtlasEmpID?.trim() === "AEC002") {
          let payload = {
            StatusInd: "A",
            HRApproval: "A",
            HRApprovedDate: currentDateTime,
            HRComments: requestDetails.HRComments || null 
          };
          await handleupdaterequest(reqid, payload);
        }
        //Manager Approval
        else {
          let payload = {
            StatusInd: "P",
            ReportingManagerApproval: "A",
            ManagerComments: requestDetails.ManagerComments || null,
            HRApproval: "P",
            ReportingManagerApprovedDate: currentDateTime,
          };
          await handleupdaterequest(reqid, payload);
        }
        toast.success("Request Approved Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
          className: "custom-toast",
        });
        navigate("/requests/leave-approvals-list");
      }
      //Approval operation ends


      //Rejection Operation starts
      else if (operation === "reject") {
        //HR Rejection
        // if (userData.Designation.trim() === "HR Manager") {
        if (userData.AtlasEmpID?.trim() === "AEC002") {
          if (
            !requestDetails.HRComments ||
            requestDetails.HRComments?.trim() === ""
          ) {
            alert("Please fill the rejection reason");
            return;
          }
          let payload = {
            StatusInd: "R",
            HRApproval: "R",
            HRComments: requestDetails.HRComments,
          };
          await handleupdaterequest(reqid, payload);
        }
        //ManagerRejection
        else {
          if (
            !requestDetails.ManagerComments ||
            requestDetails.ManagerComments?.trim() === ""
          ) {
            alert("Please fill the rejection reason");
            return;
          }
          let payload = {
            StatusInd: "R",
            ReportingManagerApproval: "R",
            ManagerComments: requestDetails.ManagerComments,
          };
          await handleupdaterequest(reqid, payload);
        }
        navigate("/requests/leave-approvals-list");
        toast.success("Request Rejected Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
          className: "custom-toast",
        });
      }
  
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <Menu link={link} />
          </div>
          <div className="emp-form d-block">
            <form className="">
              <div className="container-fluid">
                <div className="container p-3 form-wrapper border border-secondary">
                  <div className="row sub-wrapper mb-2 ">
                    <h5 className="sub-wrapper-heading">Leave Request Details</h5>
                    <div className="col">
                      {/* <div className="col-12 col-lg-6 col-xl-5 col-xxl-5"> */}
                      <div className="row">
                        {/* <div className="col-12 col-lg-6 col-xl-5 col-xxl-5"> */}
                        <div className="col-5">
                          <label
                            className="form-label customlabel"
                            htmlFor="empid"
                          >
                            AEC ID
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="empid"
                            value={requestDetails.AtlasEmpID || null}
                            placeholder=""
                            disabled
                          />
                        </div>
                        <div className="col-3"></div>
                        {/* <div className="col-12 col-lg-2 col-xl-3 col-xxl-4"> */}
                        <div className="col-4">
                        <label
                                className="form-label customlabel"
                                htmlFor="approvalmanager"
                              >
                                Approval Manager
                              </label>
                              <input
                                type="text"
                                className="form-input-text"
                                id="empid"
                                placeholder=""
                                disabled
                                readOnly
                                value={requestDetails.ReportingManagerName}
                                onChange={(e) => {
                                  handleInputChanges(e, "ReportingManagerName");
                                }}
                              />
                        </div>
                      </div>

                      <div className="row">
                        {/* <div className="col-12 col-lg-6 col-xl-5 col-xxl-5"> */}
                        <div className="col-5">
                          <label
                            className="form-label customlabel"
                            htmlFor="employeename"
                          >
                            Employee Name
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="employeename"
                            value={requestDetails.EmpName || null}
                            placeholder=""
                            maxLength="50"
                            disabled
                          />
                        </div>
                        <div className="col-3"></div>
                        <div className="col-4">
                        <label
                                className="form-label customlabel"
                                htmlFor="hrmanager"
                              >
                                HR Manager
                              </label>
                              <input
                                type="text"
                                className="form-input-text"
                                id="hrmanager"
                                placeholder=""
                                disabled
                                readOnly
                                value={"Rahima Nasser Al Hoqani"}
                              />
                        </div>
                      </div>

                      <div className="row">
                        {/* <div className="col-12 col-lg-6 col-xl-5 col-xxl-5"> */}
                        <div className="col-5">
                          <label className="form-label" htmlFor="department">
                            Department
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="department"
                            value={requestDetails.Department || null}
                            placeholder=""
                            maxLength="50"
                            disabled
                          />
                        </div>
                        <div className="col-3"></div>
                        <div className="col-4">
                        <div className="d-inline">
                                {reqid != "new" ? (
                                  <div>
                                    <label
                                      className="form-label customlabel"
                                      htmlFor="empid"
                                    >
                                      Status:
                                    </label>
                                    <div className="ps-2 d-inline">
                                      {requestDetails.StatusInd === "C" ? (
                                        <span className="custom-font-size fw-semibold">
                                          Saved
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {requestDetails.StatusInd === "A" ? (
                                        <span className="custom-font-size text-success fw-semibold">
                                          Approved
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {requestDetails.StatusInd === "P" ? (
                                        <span className="custom-font-size text-warning fw-semibold">
                                          Pending for Approval
                                          {requestDetails.ReportingManagerApproval ===
                                          "P"
                                            ? ` - ${requestDetails.ReportingManagerName}`
                                            : requestDetails.HRApproval === "P"
                                            ? ` - ${requestDetails.HRManagerName}`
                                            : ""}
                                        </span>
                                      ) : null}

                                      {requestDetails.StatusInd === "R" ? (
                                        <span className="custom-font-size  text-danger fw-semibold">
                                          Rejected
                                          {requestDetails.ReportingManagerApproval ===
                                          "R"
                                            ? ` - ${requestDetails.ReportingManagerName}`
                                            : requestDetails.HRApproval === "R"
                                            ? ` - ${requestDetails.HRManagerName}`
                                            : ""}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
                          <label className="form-label" htmlFor="designation">
                            Designation
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="desgination"
                            value={requestDetails.Designation || null}
                            placeholder=""
                            maxLength="50"
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row">
                        {/* <div className="col-12 col-lg-6 col-xl-5 col-xxl-5"> */}
                        <div className="col-5">
                          <label className="form-label" htmlFor="leavetype">
                            Leave Type
                            <span className="required"> *</span>
                          </label>
                          <select
                            name="leavetype"
                            className="form-input-select"
                            value={requestDetails.LeaveType}
                            onChange={(e) => {
                              handleInputChanges(e, "LeaveType");
                              setLeaveType(e.target.value);
                              console.log("e.target.value", e.target.value);
                            }}
                            aria-label=".form-select-sm example"
                            disabled={
                                reqid != "new" &&
                                requestDetails.StatusInd != "C"
                              }
                          >
                            <option value="">Select Leave Type</option>
                            <option value="SL">Sick Leave</option>
                            <option value="AL">Annual Leave</option>
                          </select>
                        </div>
                        <div className="col-3"></div>
                        <div className="col-4">
                        {((reqid!=="new")&&leaveAttachment)? <button
                            className="btn btn-primary ms-2"
                            type="button"
                            onClick={() => {
                              handleView(leaveAttachment);
                            }}
                          >
                            View Attachment <FaEye className="ms-2"/>
                          </button>:""}
                       
                        </div>
                      </div>
                      {/* <div className="">
                        <label
                          className="form-label customlabel"
                          htmlFor="available-days"
                        >
                          Available Days:{" "}
                        </label>
                        <p className="d-inline ms-2 fs-6">
                          {difference} 
                        </p>
                      </div> */}

                      <div className="row">
                        <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
                          <div
                          // className={leaveType === "SL" ? "d-block" : "d-none"}
                          >
                            <label
                              className={((requestDetails?.StatusInd?.trim()==="P")||(requestDetails?.StatusInd?.trim()==="A")||(requestDetails?.StatusInd?.trim()==="R"))?"d-none":"form-label customlabel"}
                            // className="form-label customlabel"
                              htmlFor="uploadfile"
                            >
                              Upload File
                            </label>
                            <input
                              type="file"
                              className={((requestDetails?.StatusInd?.trim()==="P")||(requestDetails?.StatusInd?.trim()==="A")||(requestDetails?.StatusInd?.trim()==="R"))?"d-none":"form-control form-control-sm"}
                              // {((requestDetails?.StatusInd?.trim()==="P")||(requestDetails?.StatusInd?.trim()==="A")||(requestDetails?.StatusInd?.trim()==="R"))?"d-none":"form-control form-control-sm"}
                              id="uploadfile"
                              onChange={(e) => {
                                handleFileChange(e);
                              }}
                              name="uploadfile"
                              accept="application/pdf"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
                          <label className="form-label" htmlFor="leavestarts">
                            Leave Starts
                            <span className="required"> *</span>
                          </label>
                          <input
                            type="date"
                            className="form-input-date"
                            id="leavestarts"
                            onChange={(e) => {
                                handleInputChanges(e, "LeaveStarts");
                              }}
                              value={requestDetails.LeaveStarts}
                              disabled={
                                reqid != "new" &&
                                requestDetails.StatusInd != "C"
                              }
                              
                            placeholder=""
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
                          <label className="form-label" htmlFor="leaveends">
                            Leave Ends
                            <span className="required"> *</span>
                          </label>
                          <input
                            type="date"
                            className="form-input-date"
                            id="leaveends"
                            onChange={(e) => {
                                handleInputChanges(e, "LeaveEnds");
                              }}
                              value={requestDetails.LeaveEnds}
                              disabled={
                                reqid != "new" &&
                                requestDetails.StatusInd != "C"
                              }
                            placeholder=""
                            required
                          />
                        </div>
                      </div>

                      <div className="ms-5">
                        <label
                          className="form-label customlabel"
                          htmlFor="approvalmanager"
                        >
                          Number of days:{" "}
                        </label>
                        <p className="d-inline ms-2 fs-6">
                          {difference} 
                        </p>
                      </div>
                      <div className="row">
                        <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
                        {reqid === "new" ||
                              (reqid != "new" &&
                                (requestDetails?.StatusInd?.trim() === "C" ||
                                  requestDetails.ReportingManagerApproval ===
                                    "N")) ? (
                                ""
                              ) : (
                                <>
                                  <label
                                    className="form-label"
                                    htmlFor="managercomments"
                                  >
                                    Manager Comments
                                  </label>
                                  <textarea
                                    className="form-control"
                                    id="managercomments"
                                    rows="3"
                                    columns="6"
                                    maxLength="1000"
                                    value={requestDetails.ManagerComments || ""}
                                    onChange={(e) => {
                                      handleInputChanges(e, "ManagerComments");
                                    }}
                                    //if it is not a new request and user is not reporting manager and user's desingation is HR manager
                                    readOnly={
                                      (reqid != "new" &&
                                        userData.IsReportingManager === 0) ||
                                      userData.Designation?.trim() ===
                                        "HR Manager" ||
                                      requestDetails.ReportingManagerApproval !=
                                        "P"
                                    }
                                  ></textarea>
                                </>
                              )}

                              {reqid === "new" ||
                              requestDetails.StatusInd?.trim() === "C" ||
                              requestDetails.HRApproval === "N" ? (
                                ""
                              ) : (
                                <>
                                  <label
                                    className="form-label"
                                    htmlFor="hrcomments"
                                  >
                                    HR Comments
                                  </label>
                                  <textarea
                                    className="form-control"
                                    id="hrcomments"
                                    rows="3"
                                    columns="6"
                                    maxLength="1000"
                                    value={requestDetails.HRComments || ""}
                                    onChange={(e) => {
                                      handleInputChanges(e, "HRComments");
                                    }}
                                    readOnly={
                                      userData.Designation?.trim() !==
                                        "HR Manager" ||
                                      (userData.AtlasEmpID === "AEC002" &&
                                        requestDetails.HRApproval?.trim() ===
                                          "A")
                                    }

                                    //()
                                  ></textarea>
                                </>
                              )}
                        </div>
                      </div>
                    </div>
                    {/* hiding the approve,reject, save buttons for already completed request  */}
                    {
                        ((userData.AtlasEmpID != requestDetails.AtlasEmpID )&&((requestDetails.StatusInd==="A")||(requestDetails.StatusInd==="R")))?"": <div className="d-flex justify-content-end mt-2">
             
                        {reqid != "new" &&
                            userData.IsReportingManager === 1 &&
                            userData.AtlasEmpID != requestDetails.AtlasEmpID ? (
                              <>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="success"
                                  className="me-2"
                                  onClick={(e) => handleSubmit(e, "approve")}
                                >
                                  Approve
                                </Button>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="error"
                                  onClick={(e) => handleSubmit(e, "reject")}
                                >
                                  Reject
                                </Button>
                              </>
                            ) : (
                              ""
                            )}
                      
                      <button
                              type="submit"
                              className={
                                reqid === "new" ||
                                requestDetails.AtlasEmpID != userData.AtlasEmpID ||
                                requestDetails.StatusInd === "C"
                                  ? `btn btn-primary mx-2`
                                  : "d-none"
                              }
                              onClick={(e) => {
                                handleSubmit(e, "save");
                              }}
                            >
                              Save
                            </button>
                            {userData.AtlasEmpID === requestDetails.AtlasEmpID ? (
                              <>
                                {requestDetails.StatusInd === null ||
                                requestDetails.StatusInd === undefined ||
                                requestDetails.StatusInd === "C" ? (
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={(e) => handleSubmit(e, "submit")}
                                  >
                                    Submit
                                  </button>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              ""
                            )}
                        </div>
                    }
                   
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default LeaveRequestForm2;
