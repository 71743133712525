import React from 'react';
import BeatLoader from "react-spinners/BeatLoader";

const Loader = () => {
  
  return (
    <>
    <div className="loading-container">
        <BeatLoader color="#0d6efd" size={15} /> 
      </div>
    </>
    
  )
}

export default Loader;