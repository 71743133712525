import React, { useState, useEffect } from "react";
import "./ITComplaintRequestForm.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../../../Loader";

import { Menu } from "../../../header/Header";
import { Button } from "@mui/material";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ITComplaintRequestForm = () => {
  const [loading, setLoading] = useState(false);
  const api_url = process.env.REACT_APP_API_URL;
  const userData = JSON.parse(sessionStorage.getItem("CurrentUserData"));
  const [requestDetails, setRequestDetails] = useState({});
  const today = new Date();
  const month = (today.getMonth() + 1).toString().padStart(2, "0");
  const year = today.getFullYear();
  const date = today.getDate().toString().padStart(2, "0");

  const hours = today.getHours().toString().padStart(2, "0");
  const minutes = today.getMinutes().toString().padStart(2, "0");
  const seconds = today.getSeconds().toString().padStart(2, "0");

  const currentDateTime = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;

  const navigate = useNavigate();
  const location = useLocation();
  const { reqid } = location.state || {};
  console.log("reqid", reqid);
  // const reqid = 5,
  //   mode = "edit";

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (reqid === "new") {
      setRequestDetails({
        ...requestDetails,
        AtlasEmpID: userData?.AtlasEmpID || "",
        EmpName: userData?.EmpName || "",
        Department: userData?.Department || "",
        Designation: userData?.Designation || "",
      });
      setLoading(false);
    } else {
      // Fetch data from API for existing request
      axios
        .get(`${api_url}/request/getticket-ticketdetails/${reqid}`)
        .then((res) => {
          console.log("response of getting the request", res.data.data);
          setRequestDetails(res.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching request details:", error);
          setLoading(false);
        });
    }
  }, [reqid]);

  const handleInputChanges = (event, field) => {
    let temp = { ...requestDetails };
    temp[field] = event.target.value;
    setRequestDetails(temp);
  };

  const handleSubmit = async (eve) => {
    eve.preventDefault();
    if (userData.AtlasEmpID === "AEC057") {
      await handleCloseTicket(reqid);
    } else {
      await InsertRequestDetails();
    }
  };

  const InsertRequestDetails = async () => {
    const payload = {
      AtlasEmpID: requestDetails.AtlasEmpID,
      EmpName: requestDetails.EmpName,
      Department: requestDetails.Department,
      Designation: requestDetails.Designation,
      Title: requestDetails.Title,
      Description: requestDetails.Description || null,
      SubmittedDate: currentDateTime,
      StatusInd: "O",
    };
    try {
      const response = await axios.post(
        `${api_url}/request/create-it-ticket`,
        payload,
        {
          headers: {
            "Content-Type": "application/json", // Set content type to JSON
          },
        }
      );
      if (response.data.success === true) {
        navigate("/requests/it-complaint-list");
        toast.success("Request Created Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
          className: "custom-toast",
        });

        return response.data.success;
      }
    } catch (error) {
      console.log("Error:", error);
      return false;
    }
  };

  // const handleupdaterequest = async (id, payload) => {
  //   try {
  //     const response = await axios.put(
  //       `${api_url}/request/updaterequestdetails/${id}`,
  //       payload
  //     );
  //     if (response.data.success === true) {
  //       navigate("/requests/it-complaint-list");
  //       toast.success("Request Saved Successfully", {
  //         position: "top-center",
  //         autoClose: 2000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: false,
  //         theme: "light",
  //         className: "custom-toast",
  //       });

  //       return response.data.success;

  //     }
  //   } catch (error) {
  //     console.error("There was an error updating the Request!", error);
  //     return false;
  //   } finally {
  //     // navigate("/requests/leave-request-list");
  //   }
  // };

  const handleCloseTicket = async (id) => {
    const payload = {
      StatusInd: "C",
      ClosedDate: currentDateTime,
      Comments: requestDetails.Comments || null,
    };
    try {
      const response = await axios.put(
        `${api_url}/request/closeticket/${id}`,
        payload
      );
      if (response.data.success === true) {
        navigate("/requests/it-complaint-list");
        toast.success("Request Closed Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
          className: "custom-toast",
        });

        return response.data.success;
      }
    } catch (error) {
      console.error("There was an error updating the Request!", error);
      return false;
    }
  };

  // const addRequest = async () => {
  //   InsertRequestDetails();
  // };
  // const EditRequest = async (id) => {};

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="d-flex justify-content-between">
        <Menu link="/requests/it-complaint-list" />
      </div>
      <div className="emp-form d-block">
        {/* Form Starts */}
        <form
          className=""
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="emp-form-save-button"></div>
          <div className="container-fluid">
            <div className="container p-3 form-wrapper border border-secondary">
              <div className="row sub-wrapper ">
                <h5 className="sub-wrapper-heading">Request Details</h5>
                <div className="col-6">
                  <label className="form-label" htmlFor="atlasempid">
                    AEC ID
                  </label>
                  <input
                    type="text"
                    className="form-input-text"
                    id="atlasempid"
                    placeholder=""
                    value={requestDetails.AtlasEmpID || null}
                    maxLength="10"
                    onChange={(e) => {
                      handleInputChanges(e, "AtlasEmpID");
                    }}
                    required
                    disabled
                  />
                  <label className="form-label" htmlFor="applicantname">
                    Employee Name
                  </label>
                  <input
                    type="text"
                    className="form-input-text"
                    id="applicantname"
                    placeholder=""
                    value={requestDetails.EmpName || null}
                    maxLength="150"
                    onChange={(e) => {
                      handleInputChanges(e, "EmpName");
                    }}
                    required
                    disabled
                  />

                  <label className="form-label" htmlFor="department">
                    Department
                  </label>

                  <input
                    type="text"
                    className="form-input-text"
                    id="department"
                    placeholder=""
                    value={requestDetails.Department || null}
                    maxLength="150"
                    onChange={(e) => {
                      handleInputChanges(e, "Department");
                    }}
                    disabled
                  />

                  <label className="form-label" htmlFor="designation">
                    Designation
                  </label>
                  <input
                    type="text"
                    className="form-input-text"
                    id="designation"
                    placeholder=""
                    value={requestDetails.Designation || null}
                    maxLength="150"
                    onChange={(e) => {
                      handleInputChanges(e, "Designation");
                    }}
                    required
                    disabled
                  />

                  <label className="form-label" htmlFor="title">
                    Title<span className="required"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-input-text"
                    id="title"
                    placeholder=""
                    value={requestDetails.Title || null}
                    maxLength="300"
                    onChange={(e) => {
                      handleInputChanges(e, "Title");
                    }}
                    required
                    disabled={reqid != "new"}
                  />

                  <label className="form-label" htmlFor="description">
                    Description
                  </label>
                  <textarea
                    className="form-input-textarea"
                    id="description"
                    rows="3"
                    columns="3"
                    onChange={(e) => {
                      handleInputChanges(e, "Description");
                    }}
                    value={requestDetails.Description || null}
                    disabled={reqid != "new"}
                  ></textarea>

                  {reqid != "new" ? (
                    <>
                      <label className="form-label" htmlFor="comments">
                        Comments
                      </label>
                      <textarea
                        className="form-input-textarea"
                        id="comments"
                        rows="3"
                        columns="3"
                        onChange={(e) => {
                          handleInputChanges(e, "Comments");
                        }}
                        value={requestDetails.Comments || null}
                        readOnly={
                          userData.AtlasEmpID != "AEC057" ||
                          requestDetails.StatusInd === "C"
                        }
                      ></textarea>
                    </>
                  ) : (
                    ""
                  )}
                  {reqid === "new" ? (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="mt-2"
                    >
                      Raise Ticket
                    </Button>
                  ) : (
                    ""
                  )}
                  {reqid !== "new" && userData.AtlasEmpID === "AEC057" ? (
                    requestDetails.StatusInd === "C" ? null : (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="ms-5 mt-2"
                      >
                        Close Ticket
                      </Button>
                    )
                  ) : null}
                </div>
                <div className="col-2"></div>

                <div className="col-4">
                  {reqid != "new" ? (
                    <>
                      <div className="mt-5">
                        <label
                          className="form-label customlabel"
                          htmlFor="empid"
                        >
                          Status:
                        </label>
                        <div className="ps-2 d-inline">
                          {requestDetails.StatusInd === "O" ? (
                            <span className="custom-font-size fw-semibold">
                              Open
                            </span>
                          ) : (
                            ""
                          )}
                          {requestDetails.StatusInd === "C" ? (
                            <span className="custom-font-size text-success fw-semibold">
                              Closed
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ITComplaintRequestForm;
