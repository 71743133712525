import React, { useEffect, useState } from "react";
import { Menu } from "../../header/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import { MdDelete } from "react-icons/md";
import { FaUpload } from "react-icons/fa6";
import { FaEye } from "react-icons/fa6";

import { Button } from "@mui/material";
import "./HRForms.css";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const HRForms = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const navigate = useNavigate();
  const [formDetails, setFormDetails] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);

  const { CVID, mode } = location.state || {};

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);

  const handleInputChanges = (event, field) => {
    let temp = { ...formDetails };
    temp[field] = event.target.value;
    setFormDetails(temp);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      alert("Please select a valid PDF file.");
    }
  };

  useEffect(() => {
    
    if (mode === "edit") {
      getFormDetails(CVID);
    }
    else if (mode === "add") {
      getLatestFileNumber();
    }
  }, []);


  const InsertFileDetails = async () => {
    let temp = { 
      FormFrom:"HR",
      RefID:formDetails.FileRefID,
      FileNumber:formDetails.FileNumber,
      FormName:formDetails.FormName, 
      Remarks:formDetails.Remarks,
      CurrentRev:1
    };
    console.log("FormDetails", temp);
    await axios
      .post(`${api_url}/hr/createfiledetail`, temp, {
        headers: {
          "Content-Type": "application/json", // Set content type to JSON
        },
      })
      .then((response) => {
        console.log("Form details created successfully", response);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };


  const getLatestFileNumber = async() => {
    await axios
      .get(`${api_url}/hr/getlatestfilenumber`)
      .then((res) => {
        const fileRefID = res.data.FileRefID;
        const FileRefIdPrefix = "AEC-HR-";
        const paddedfileRefIDnumber = fileRefID.toString().padStart(3, "0");
        const FileNumber = FileRefIdPrefix + paddedfileRefIDnumber;
        const temp = { ...formDetails }; //destructuring the employee state using temp variable to update empid and refid
        temp["FileRefID"] = fileRefID;
        temp["FileNumber"] = FileNumber;
        if(mode == "add"){
          temp["CurrentRev"] = 1  
        }
        
        setFormDetails(temp);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const UploadForm = async (file, cvid) => {
    try {
      const formData = new FormData();
      formData.append("CVID", cvid);
      formData.append("Resume", file);
      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }
      await axios.post(`${api_url}/recruitment/uploadcv/${cvid}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.error("Error uploading CV", error);
    }
  };

  const handleSubmit = (eve) => {
    eve.preventDefault();
    mode === "add" ? addForm() : EditForm(CVID);
  };

  const addForm = async () => {
    
      await InsertFileDetails();
      // await UploadForm(selectedFile, latestFormID);
      toast.success("Form Details Added Successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });

  };
  const EditForm = async (id) => {
    await updateFormDetails(id);
    if (selectedFile) {
      await UploadForm(selectedFile, id);
      toast.success("Form Details Updated Successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });
    }
  };

  const getFormDetails = async (id) => {
    try {
      const res = await axios.get(
        `${api_url}/recruitment/getapplicantdetails/${id}`
      );
      console.log("response from get requrest", res.data[0]);
      let form = res.data[0];
      setFormDetails(form);
    } catch (err) {
      console.log(err);
    }
  };

  const updateFormDetails = async (eid) => {
    await axios
      .put(`${api_url}/recruitment/updateapplicantdetails/${eid}`, formDetails)
      .then((response) => {
        if (response) {
          navigate("/cvdb");
        }
      })
      .catch((error) => {
        console.error("There was an error updating!", error);
      });
  };

  const handleDelete = async (cvid) => {
    await axios
      .delete(`${api_url}/recruitment/deleterecord/${cvid}`)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    navigate("/hrformsmgmt");
    toast.error("Applicant Details Deleted!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      theme: "light",
    });
  };

  const labels = ["REV No", "CreatedDate", "", "", ""];

  const RedContainedButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(theme.palette.error.main), // Sets text color to contrast with red
    backgroundColor: theme.palette.error.main, // Sets background color to red
    "&:hover": {
      backgroundColor: theme.palette.error.dark, // Sets background color to darker red on hover
    },
  }));

  let records = ["sample"];

  return (
    <>
      {/* <div className=" header-nav-bar"> */}
      <div className="d-flex justify-content-between">
        <Menu link="/hrformsmgmt" />
      </div>
      <div className="emp-form d-block">
        {/* Form Starts */}
        <form
          className=""
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="emp-form-save-button">
            {mode === "edit" ? (
              <RedContainedButton
                variant="contained"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  handleDelete(CVID);
                }}
                className="m-2"
              >
                Delete
              </RedContainedButton>
            ) : (
              ""
            )}
            <Button type="submit" variant="contained" color="primary">
              Save Details
            </Button>
          </div>
          <div className="container-fluid">
            <div className="container p-3 form-wrapper border border-secondary">
              <div className="row sub-wrapper mb-2 ">
                <h5 className="sub-wrapper-heading">Form Details</h5>
                <div className="col-6">
                  <label className="form-label" htmlFor="filename">
                    File Name<span className="required"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-input-text"
                    id="filename"
                    placeholder=""
                    value={formDetails.FormName || ""}
                    maxLength="150"
                    onChange={(e) => {
                      handleInputChanges(e, "FormName");
                    }}
                    required
                  />

                  <label className="form-label" htmlFor="filenumber">
                    File Number<span className="required"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-input-text"
                    id="filenumber"
                    placeholder=""
                    value={formDetails.FileNumber}
                    maxLength="150"
                    onChange={(e) => {
                      handleInputChanges(e, "FileNumber");
                    }}
                    required
                    disabled
                  />
                            <label className="form-label" htmlFor="currentrevision">
                    Current Revision
                  </label>
                  <input
                    type="text"
                    className="form-input-text"
                    id="currentrevision"
                    placeholder=""
                    value={formDetails.CurrentRev  || ""}
                    maxLength="150"
                    onChange={(e) => {
                      handleInputChanges(e, "CurrentRev");
                    }}
                    required
                    disabled
                  />
                  {mode==="add"?"":
                  (
                  <>
                  <label className="form-label" htmlFor="createddate">
                    Created Date
                  </label>
                  <p id="createddate">{formDetails.CreatedDate || null}</p>
                  <label className="form-label" htmlFor="lastmodifieddate">
                    Last Modified Date
                  </label>
                  <p id="lastmodifieddate">{formDetails.LastModifiedDate || null}</p>
                </>
                )
                  }
                  <label
                            className="form-label"
                            htmlFor="remarks"
                          >
                            Remarks
                          </label>
                          <textarea
                            className="form-input-textarea"
                            id="remarks"
                            rows="3"
                            columns="6"
                            onChange={(e) => {
                              handleInputChanges(e, "Remarks");
                            }}
                            value={formDetails.Remarks || ""}
                          ></textarea>
                  
                </div>
              </div>
              <div className="row sub-wrapper">
                <div className="col-auto ms-auto">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    endIcon={<AddIcon />}
                  >
                    Revision
                  </Button>
                </div>
                <table className="table table-hover table-responsive">
                  <thead>
                    <tr>
                      {labels.map((item, index) => (
                        <th
                          scope="col"
                          key={index}
                          className="table-header-font-size"
                        >
                          {item}{" "}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="table-body-font-size">
                    {records.map((item, index) => {
                      return (
                        <tr className="py-0" key={index}>
                          <td className="py-0"></td>
                          <td className="py-0">{item.CreatedDate}</td>
                          <td className="py-0">
                            <FaUpload />
                          </td>
                          <td className="py-0">
                            <FaEye />
                          </td>
                          <td className="py-0">
                            <MdDelete />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default HRForms;
