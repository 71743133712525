import React, { useState, useRef } from "react";
import { Link, Navigate } from "react-router-dom";
import { FaBell, FaUser, FaBars } from "react-icons/fa";
import { FaEllipsisVertical } from "react-icons/fa6";
import "./Header.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { FaClock } from "react-icons/fa";



export function Menu(props) {
  return (
    <div>
      <Link to={props.link} className="text-decoration-none">
        <KeyboardBackspaceIcon
          style={{
            fontWeight: "bold",
            cursor: "pointer",
            marginLeft: "5px",
          }}
        />
        <p className="p-2 d-inline">Go back</p>
      </Link>
    </div>
  );
}

const Header = ({ onToggle, onToggleReportingManager }) => {
  // const userData = JSON.parse(sessionStorage.getItem("CurrentUserData"));
  // const reportingManager = userData.IsReportingManager === 1;

  const [iconsVisible, setIconsVisible] = useState(false);
  const [userMenuVisible, setUserMenuVisible] = useState(false);
  const dropdownRef = useRef(null);

  const handleIconsVisible = () => {
    setIconsVisible((prev) => !prev);
  };

  const handleLogout = () => {
    onToggleReportingManager(false);
    localStorage.clear();
    sessionStorage.clear();
    return <Navigate to="/" replace />;
  };

  const closeUserMenu = () => {
    setUserMenuVisible(false);
  };

  const handleMouseEnter = () => {
    setUserMenuVisible(true);
  };

  const handleMouseLeave = () => {
    setUserMenuVisible(false);
  };

  return (
    <header className="header">
      <div className="title-wrapper">
        <div className="toggle">
          <button className="sidebar-toggle border-0 bg-white" onClick={onToggle}>
            <FaBars />
          </button>
        </div>
        <div className="vertical-dots" onClick={handleIconsVisible}>
          <FaEllipsisVertical />
        </div>
      </div>

      <div className={`icons ${iconsVisible ? "icons-visible" : ""}`}>
        <div className="profile-icon">
          <FaBell className="icon" />
        </div>
        <div className="d-block">
          <Link to="/user/userpunch">
            <FaClock className="icon" />
          </Link>
        </div>
        <div
          className="dropdown"
          ref={dropdownRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <FaUser className="icon" />
          <div className={`dropdown-menu${userMenuVisible ? " show" : ""}`}>
            <div className="dropdown-item">Profile</div>
            <div className="dropdown-item">Settings</div>
            <Link className="text-decoration-none" to="/user/change-password-form">
              <div className="dropdown-item">Change Password</div>
            </Link>
            <Link className="text-decoration-none" to="/">
              <div className="dropdown-item" onClick={handleLogout}>
                Logout
              </div>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
